import * as React from "react";
import {
  Tabs,
  Grid,
  TextField,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import "../../../App.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  FormDetails,
  ReportDetails,
} from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../../assets/images/Group 42722.svg";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import Paths from "../../../components/constants/Path";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import { SelectComponent } from "../../../components/FormComponents/SelectComponent";
import { useState, useMemo } from "react";
import UploadTemplate from "../../../components/Wrapper/UploadTemplate";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, SxProps } from "@mui/system";
import { appBarClasses } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchDealList,
  getLedgerCodes,
  setPurpose,
  getAllBranches,
  getSOAReport,
  fetchYearData,
  fetchSourceDealList,
  clearUploadStateAction,
} from "../../../redux/Phase2/UploadReportSlice";
import { useEffect } from "react";
import moment from "moment/moment";
import {
  clearSucessAndErrorAction,
  fetchKanazaData,
  fetchReportData,
} from "../../../redux/Phase2/SearchReports";
import { GeneratedReportFile } from "../../../components/Phase2/UploadReports/GeneratedReportFile";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import useDebounce from "./../../../Hooks/useDebounce";
import { GeneratedReportFileV2 } from "./../../../components/Phase2/UploadReports/GenerateReportV2";
import { getVoucherDetails } from "./../../../redux/Phase2/UploadReportSlice";
import { useHistory } from "react-router-dom";
import { GenerateReportDescIcon, GenerateReportMainBox } from "./SearchReports";
import Styles from "./SearchReports.module.css";
import { reportConstants } from "../../../components/constants/GlobalConstants";
import LedgerReportComponent from "../../../components/Reports/LedgerReportComponent";
import PoolWiseReportComponent from "../../../components/Reports/PoolWiseReportComponent";
import VoucherReportComponent from "../../../components/Reports/VoucherReportComponent";
import SOAReportComponent from "../../../components/Reports/SOAReportComponent";
import SynergyReportComponent from "../../../components/Reports/SynergyReportComponent";
import TrialBalancesReportComponent from "../../../components/Reports/TrialBalancesReportComponent";
import AsOnDateReportComponent from "../../../components/Reports/AsOnDateReportComponent";
import DefaultReportComponent from "../../../components/Reports/DefaultReportComponent";
import ABCReportComponent from "../../../components/Reports/ABCReportComponent";


export default function GenerateReports() {
  const [status, setstatus] = useState(true);
  const [ledgerStatus, setLedgerStatus] = useState(true);
  const [selectedAll, setSelectedAll] = useState(false);
  const [trailStatus, setTrailStatus] = useState(true);
  const [asOnDateStatus, setAsOnDate] = useState(true);
  const [multiDeal, setMultiDeal] = useState([]);
  const [multiledgercodes, setMultiledgercodes] = useState([]);
  const [multiBranch, setMultiBranch] = useState([]);
  const [reportName, setReportName] = useState(null);
  const [selectedReportName, setSelectedReportName] = useState(null);
  const [value, setValue] = React.useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ledgerstartDate, setledgerstartDate] = useState(null);
  const [ledgerendDate, setledgerEndDate] = useState(null);
  const [ledgerCode, setLedgerCode] = useState("");
  const [filter, setFilter] = useState("");
  const [loanNo, setLoanNo] = useState("");
  const [monthList, setMonthList] = useState([]);
  const [entityStatus, setEntityStatus] = useState(true)        //extra
  const [entity, setEntity] = useState("")
  const [reportTypeName, setReportTypeName] = useState("")
  const [voucherDetails, setVoucherDetails] = useState({
    voucherNumber: "",
  });
  const [statusData1, setStatusData1] = useState({
    selectReportType: "",
  });
  const [soaDetails, setSoaDetails] = useState({
    loanNumber: "",
    financialYear: "",
  });
  const [statusData, setStatusData] = useState({
    dealName: "",
    transactionMonth: "",
    typeOfReport: "",
    year: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const allBranchesLists = useSelector(
    (state) => state.uploadReport.allBranches
  );
  const yearData = useSelector((state) => state.uploadReport.yearData);
  const ledgerLists = useSelector((state) => state.uploadReport.ledgerCodes);

  let debounceSearch = useDebounce(filter, 700);


  let SerachReportState = useSelector((state) => state.serachReport);
  let uploadReportState = useSelector((state) => state.uploadReport);

  const transactionHanlder = (name) => (e) => {
    setVoucherDetails({ ...voucherDetails, [name]: e.target.value });
  };

  const [previousMonthList, setPreviousMonthList] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  // Reports List Data
  const [reportList, setReportList] = useState([
    {
      id: 1,
      name: "Ledger Report",
    },

    {
      id: 2,
      name: "Accounting Report",
    },
    {
      id: 3,
      name: "Poolwise Report",
    },
    {
      id: 4,
      name: "ABC Report",
    },
    {
      id: 5,
      name: "Synergy Extracted Report",
    },
    {
      id: 6,
      name: "Trial Balances Report",
    },
    // {
    //   id: 7,
    //   name: "As On Date Report",
    // },
    {
      id: 8,
      name: "Voucher Report",
    },
    {
      id: 9,
      name: "SOA Report",
    },
    {
      id: 10,
      name: "Consolidated Accounting Report",
    },
    {
      id: 11,
      name: "Consolidated Success Report",
    },
    {
      id: 12,
      name: "Before Approval Report",
    },
  ]);

  const [sourceList, setSourceList] = useState([
    {
      id: 1,
      name: "DHFL",
    },

    {
      id: 2,
      name: "PEL",
    },
    {
      id: 3,
      name: "PCHFL",
    },

  ]);
  const [entityData, setEntityData] = useState({
    sourceOfReport: "",
  })
  const [loanNumber, setLoanNumber] = useState("");
  const [branch, setBranch] = useState("");

  console.log({ yearData })
  // const [statusData, setStatusData] = useState({
  //   dealName: "",
  //   transactionMonth: "",
  //   typeOfReport: "",
  //   year: "",
  // });


  const handleVoucherReport = async () => {
    setVoucherDetails({ ...voucherDetails, ["voucherNumber"]: "" })

    dispatch(
      getVoucherDetails({
        transaction: voucherDetails.voucherNumber,
        year: voucherDetails.financialYear,
      })
    ).then((res) => {

      if (res.payload.data.data.data.length < 1) {
        dispatch(showErrorAlertAction("No Data Found"));
        return;
      } else {
        return history.push(Paths.VIEW_VOUCHER);
      }
    });
  };

  useEffect(() => {
    if (
      statusData.dealName === "" &&
      statusData.transactionMonth === "" &&
      statusData.typeOfReport === ""
    ) {
      setstatus(false);
    } else {
      setstatus(false);
    }
  }, [statusData]);

  // BreadCrumb Component starts here
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Reports",
    },
  ];
  // BreadCrumb Component ends here

  let icon = (<DescriptionIcon sx={GenerateReportDescIcon} />);

  //Useffect for fetching the deal list

  useEffect(() => {
    dispatch(fetchDealList());

    dispatch(getLedgerCodes());
    // dispatch(getAllBranches());
    return () => {
      dispatch(clearUploadStateAction());
    }
  }, []);

  useEffect(() => {
    dispatch(clearSucessAndErrorAction());
  }, []);

  useEffect(() => {
    if (statusData.dealName === "") {
      setAsOnDate(true);
    } else {
      setAsOnDate(false);
    }
  }, [statusData.dealName]);

  useEffect(() => {
    if (statusData?.typeOfReport == "" || statusData1?.selectReportType == "" || entityStatus == true || startDate == null || endDate == null) {
      setTrailStatus(true);
    } else {
      setTrailStatus(false)
    }
  }, [statusData1, statusData, entityStatus, startDate, endDate]);

  useEffect(() => {
    let report = reportList.find((item) => item.id === statusData.typeOfReport);

    if (statusData.typeOfReport && statusData.dealName !== "") {
      if (
        report?.name === "ABC Report" ||
        report?.name === "Poolwise Report" ||
        report?.name === "Accounting Report" ||
        report?.name === "Consolidated Accounting Report" ||
        report?.name === "Consolidated Success Report" || 
        report?.name === "Before Approval Report"
      ) {
        // Special handling for "Consolidated Accounting Report" & "Consolidated Success Report""
        let reportName;
        if (report?.name === "Consolidated Accounting Report" || report?.name === "Consolidated Success Report") {
          reportName = reportTypeName;
        } else {
          reportName = report?.name.toUpperCase().split(" ").join("_");
        }
        dispatch(
          fetchYearData({
            reportName: reportName,
            dealId: selectedAll ? "ALL" : statusData.dealName,
            entity: entity,
          })
        ).then((res) => {
          console.log(res);
        });
      }
    }
  }, [statusData.typeOfReport, statusData.dealName]);

  useEffect(() => {
    if (yearData?.months.length > 0) {
      setMonthList(yearData.months);
    }
  }, [yearData?.months]);
  useEffect(() => {
    setMonthList(previousMonthList);
    setStatusData({ ...statusData, year: "" });
  }, [statusData.typeOfReport]);

  //Useffect for setting the report name
  useEffect(() => {
    for (let i = 0; i < reportList.length; i++) {
      if (reportList[i].id == statusData.typeOfReport) {
        if (reportList[i].name == "Accounting Report") {
          setReportName("getNetAccountingEntries");
          setSelectedReportName("Accounting Report");
        } else if (reportList[i].name == "Ledger Report") {
          setReportName("getLedgerReport");
          setSelectedReportName("Ledger Report");
        } else if (reportList[i].name == "Poolwise Report") {
          setReportName("getSecuritizationReport");
          setSelectedReportName("Poolwise Report");
          setReportTypeName("POOLWISE_REPORT")
        } else if (reportList[i].name == "ABC Report") {
          setReportName("getSecuritizationReport");
          setSelectedReportName("ABC Report");
          setReportTypeName("ABC_REPORT")
        } else if (reportList[i].name == "Consolidated Accounting Report") {
          setReportName("getSecuritizationReport");
          setSelectedReportName("Consolidated Accounting Report");
          setReportTypeName("ACCOUNTING_REPORT")
        } else if (reportList[i].name == "Consolidated Success Report") {
          setReportName("getSecuritizationReport");
          setSelectedReportName("Consolidated Success Report");
          setReportTypeName("SUCCESS_REPORT")
        } else if (reportList[i].name == "Before Approval Report") {
          setReportName("getSecuritizationReport");
          setSelectedReportName("Before Approval Report");
          setReportTypeName("BEFORE_APPROVAL_REPORT")
        } else if (reportList[i].name == "Synergy Extracted Report") {
          setSelectedReportName("Synergy Extracted Report");
        } else if (reportList[i].name == "Trial Balances Report") {
          setReportName("trialbalances");
          setSelectedReportName("Trial Balances Report");
        } else if (reportList[i].name == "As On Date Report") {
          setReportName("getSecuritizationReport");
          setSelectedReportName("As On Date Report");
        } else if (reportList[i].name == "Voucher Report") {
          setReportName("Voucher Report");
          setSelectedReportName("Voucher Report");
        } else if (reportList[i].name == "SOA Report") {
          setReportName("SOA Report");
          setSelectedReportName("SOA Report");
        }
      }
    }
  }, [statusData.typeOfReport]);

  useEffect(() => {
    if (SerachReportState?.reportData?.file) {
      setLedgerStatus(true);
      setstatus(true);
    }
  }, [SerachReportState.reportData]);


  const handleSoaReport = async () => {
    console.log("handler");
    setSoaDetails({
      ...soaDetails,
      loanNumber: "",
    })
    dispatch(
      getSOAReport({
        loanNumber: soaDetails.loanNumber,
        financialYear: soaDetails.financialYear,
      })
    ).then((res) => {
      console.log({ res });
      console.log({ resdta: res.payload.data.data.error });
      if (res.payload.data.data.error) {
        dispatch(showErrorAlertAction(res.payload.data.data.error));
        return;
      }
      if (res.payload.data.data.data.length < 1) {
        dispatch(showErrorAlertAction("No Data Found"));
        return;
      } else {
        return history.push({
          pathname: "/view/soa-report-details",
          state: { financialYear: soaDetails.financialYear },
        });
      }
    });
    console.log("hanlder");
  };


  // Header Component of the card starts here
  const DocumentHeader = ({ title }) => {
    return (
      <div
        style={{
          backgroundColor: "#E4EAFA",
          height: "54px",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          paddingLeft: "24px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <DescriptionIcon
            sx={{ color: "#5E7387", width: "20px", height: "20px" }}
          ></DescriptionIcon>
          <Typography
            fontWeight="700"
            fontSize="14px"
            sx={{ color: "#5E7387" }}
          >
            {title}
          </Typography>
        </Stack>
      </div>
    );
  };
  // Header Component of the card ends here



  useEffect(() => {
    for (let i = 0; i < sourceList?.length; i++) {
      if (sourceList[i]?.id == entityData?.sourceOfReport) {
        if (sourceList[i]?.name == "DHFL") {
          setEntity("DHFL");
          setEntityStatus(false)
          return;
        }
        if (sourceList[i]?.name == "PEL") {
          setEntity("PEL");
          setEntityStatus(false)
          return;
        }
        if (sourceList[i]?.name == "PCHFL") {
          setEntity("PCHFL");
          setEntityStatus(false)
          return;
        }
      }
    }
  }, [entityData.sourceOfReport])



  useEffect(() => {

    dispatch(fetchSourceDealList({ entity: entity }))

  }, [entity])



  //Useffect to check report is null or not
  useEffect(() => {
    if (
      SerachReportState["reportData"] != null &&
      SerachReportState["reportData"]["message"] === "No Data Found"
    ) {
      dispatch(showErrorAlertAction("No Data Found"));
    }
  }, [SerachReportState]);

  //? it will run whenever the state will change or update
  useEffect(() => {
    checkStatus();
  }, [statusData, ledgerCode]);

  useEffect(() => {
    if (loanNo.length == 0) {
      setstatus(true);
    } else if (loanNo.length != 0 && loanNo.length < 11) {
      setstatus(true);
    } else {
      setstatus(false);
    }
  }, [loanNo]);

  useEffect(() => {
    if (selectedAll === true) {
      setMultiDeal([...uploadReportState.dealList]);
    } else {
      setMultiDeal([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    if (selectedAll === true) {
      setMultiledgercodes([...uploadReportState?.ledgerCodes]);
    } else {
      setMultiledgercodes([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    if (selectedAll === true) {
      setMultiBranch([...uploadReportState?.allBranches]);
    } else {
      setMultiBranch([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    const commaSep = multiDeal.map((item) => item.id).join(", ");
    setStatusData({ ...statusData, dealName: commaSep });
  }, [multiDeal]);

  useEffect(() => {
    if (
      multiDeal.length == 0 &&
      ledgerCode == "" &&
      startDate == null &&
      endDate == null
    ) {
      setLedgerStatus(true);
    } else if (
      multiDeal.length != 0 &&
      ledgerCode != "" &&
      startDate != null &&
      endDate != null
    ) {
      setLedgerStatus(false);
    }
  }, [multiDeal, startDate, endDate, ledgerCode]);

  const handleKanazaReport = () => {

    setstatus(true)
    dispatch(clearSucessAndErrorAction());
    dispatch(fetchKanazaData(loanNo)).then((res) => {
      console.log({ res });
      if (res.payload.status == 200) {
        // window.location.href = res.payload.data.data.kanazaURL;
        window.open(res.payload.data.data.url, "_blank");
      } else {
        console.log(res.payload.response.data.data.error);
        dispatch(showErrorAlertAction(res.payload.response.data.data.error));
      }
    });
  };

  //Generate Report Function starts here
  const handleGenerateReport = () => {
    dispatch(clearSucessAndErrorAction());
    let commaSep, multiBranchCommaSep, multiLedgerCodeCommaSep;

    if (selectedAll) {
      commaSep = "ALL";
    } else {
      commaSep = multiDeal.map((item) => item.id).join(",");
    }
    console.log({ commaSep, multiBranchCommaSep, multiLedgerCodeCommaSep });

    if (selectedAll) {
      multiBranchCommaSep = "ALL";
    } else {
      multiBranchCommaSep = multiBranch.map((item) => item.branch).join(",");
    }

    setledgerstartDate(startDate);
    setledgerEndDate(endDate);

    if (selectedAll) {
      multiLedgerCodeCommaSep = "ALL";
    } else {
      multiLedgerCodeCommaSep = multiledgercodes
        .map((item) => item.id)
        .join(",");
    }

    setstatus(true)
    setLedgerStatus(true)
    // setVoucherDetails({ ...voucherDetails, ["voucherNumber"]: ""})

    setTrailStatus(true)
    setAsOnDate(true)
    dispatch(
      fetchReportData({
        month: statusData.transactionMonth,
        year: statusData.year || "",
        dealId:
          selectedReportName === "Ledger Report"
            ? commaSep
            : selectedReportName === "Poolwise Report" || selectedReportName === "ABC Report" || selectedReportName === "Consolidated Accounting Report" || selectedReportName === "Consolidated Success Report" || selectedReportName === "Before Approval Report"
              ? commaSep
              : statusData.dealName,
        reportType: reportName,
        endDate:
          selectedReportName === "Ledger Report" ||
            selectedReportName === "Trial Balances Report"
            ? moment(endDate["$d"]).format("DD-MM-YYYY")
            : "",
        startDate:
          selectedReportName === "Ledger Report" ||
            selectedReportName === "Trial Balances Report"
            ? moment(startDate["$d"]).format("DD-MM-YYYY")
            : "",
        ledgerCode: ledgerCode,
        multiBranch: multiBranchCommaSep,
        multiLedgerCode: multiLedgerCodeCommaSep,
        type: statusData1.selectReportType == 1 ? "SUMMARY" : "DETAILED",
        reportName: reportTypeName,
        entity: entity,
      })
    ).then((res) => {

      if (res?.payload?.status == 200) {
        setLoanNo("");
        setLedgerCode("");
        setstartDate(null);

        setValue(null);
        setFilter("");
        setEndDate(null);
        setMultiDeal([]);
        setMultiledgercodes([]);
        setMultiBranch([]);
        setSelectedAll(false);

        setAsOnDate(true);
        setStatusData({
          ...statusData,
          dealName: "",
          transactionMonth: "",
          typeOfReport: "",

          year: "",
        });
        setEntityData({
          ...entityData,
          sourceOfReport: "",
        })
        setStatusData1({
          ...statusData1,
          selectReportType: "",
        });
        setBranch("");
        setstatus(true);
        setLedgerStatus(true);
        setTrailStatus(true);
        setEntity("")
        setReportTypeName("")
        setEntityStatus(false)
      }
    });
  };
  //Generate Report Function ends here

  console.log({ loanNo });

  //Handle Input Change Function starts here
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setStatusData({ ...statusData, [name]: value });
    dispatch(clearSucessAndErrorAction());
  };

  const handleEntityChange = (name) => (e) => {
    let value = e.target.value;
    setEntityData({ ...statusData, [name]: value });
    dispatch(clearSucessAndErrorAction());
  };
  //Handle Input Change Function ends here

  //Handle Input Change Function starts here
  const handleInputChange1 = (name) => (e) => {
    let value = e.target.value;
    setStatusData1({ ...statusData, [name]: value });
    dispatch(clearSucessAndErrorAction());
  };
  //Handle Input Change Function ends here

  //Button disable\enable function starts here
  const checkStatus = () => {
    let valuesArray = Object.values(statusData);

    let statusObject = statusData;
    delete statusObject.ledgerCode;
    for (const key in statusObject) {
      if (statusObject[key] == 0) {
        setstatus(true);
        break;
      }
      setstatus(false);
    }
  };
  //Button disable\enable function ends here

  //Handle Multiple selection deal Function starts here
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let duplicateRemoved = [];
    console.log({ value });
    value.forEach((item) => {
      console.log({ item });
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });
    console.log({ duplicateRemoved });
    setMultiDeal(duplicateRemoved);
    setMultiledgercodes(duplicateRemoved);
  };
  //Handle Multiple selection deal Function ends here

  //Handle Multiple selection deal Function starts here
  const handleBranchChange = (event) => {
    const {
      target: { value },
    } = event;
    let duplicateRemoved = [];
    console.log({ value });
    value.forEach((item) => {
      console.log({ item });
      if (duplicateRemoved.findIndex((o) => o.branch === item.branch) >= 0) {
        duplicateRemoved = duplicateRemoved.filter(
          (x) => x.branch === item.branch
        );
      } else {
        duplicateRemoved.push(item);
      }
    });
    console.log({ duplicateRemoved });

    setMultiBranch(duplicateRemoved);
  };
  //Handle Multiple selection deal Function ends here

  // Placeholder component
  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  //MenuProps for the select component
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
        background: "#EBEEF0",
      },
    },
  };

  //Report Heading
  const reportHeading = `${selectedReportName} for ${statusData.transactionMonth}`;
  const ledgerReportHeading = `${selectedReportName} for ${moment(
    ledgerstartDate && ledgerstartDate["$d"]
  ).format("DD-MM-YYYY")} to ${moment(
    ledgerendDate && ledgerendDate["$d"]
  ).format("DD-MM-YYYY")}`;

  let defaultProps = {
    options: ledgerLists,
    getOptionLabel: (option) =>
      `${option?.systemCode}(${option?.purpose?.name})`,
  };

  const containsText = (text, searchText, dealCodeSerach) =>
    (text && text.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
    dealCodeSerach.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const containsText1 = (text, searchText, systemCode) =>
    (text && text.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
    systemCode.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const branchContainsText = (text, searchText) =>
    text && text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  let displayedOptions = useMemo(
    () =>
      uploadReportState.dealList.filter((option) =>
        containsText(option.name, debounceSearch, option.dealCode)
      ),
    [debounceSearch]
  );

  let ledgerCodeOptions = useMemo(
    () =>
      uploadReportState.ledgerCodes.filter((option) =>
        containsText(option?.purpose?.name, debounceSearch, option?.systemCode)
      ),
    [debounceSearch]
  );

  let branchOptions = useMemo(
    () =>
      uploadReportState.allBranches.filter((option) =>
        branchContainsText(option?.branch, debounceSearch)
      ),
    [debounceSearch]
  );


  useEffect(() => {
    setFilter("");
  }, [selectedReportName, statusData.dealName, multiDeal]);

  useEffect(() => {
    setStatusData({ ...statusData, dealName: "", transactionMonth: "" });
    setMultiDeal([]);
    setMultiledgercodes([]);
    setMultiBranch([]);
  }, [selectedReportName]);

  function getFinancialYears() {
    const currentYear = new Date().getFullYear();
    let years = [];
    const startYear = 2022;
    for (let year = startYear; year <= currentYear; year++) {
      years.push(`${year}-${year + 1}`);
    }
    return years;
  }



  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <Box sx={GenerateReportMainBox}>
          <div>
            <ReportDetails
              icon={icon}
              iconColor="#DBE3F8"
              formName="View Reports"
            />
          </div>
          <div className={Styles.GenerateReportImageDiv}>
            <img
              className={Styles.GenerateReportImage}
              src={Image}
              alt="image"
            />
          </div>
        </Box>

        {uploadReportState["dealList"].length === 0 ? (
          <div className={Styles.GenerateReportStateLoader}>
            <Typography>Loading....</Typography>
          </div>
        ) : (
          //View Report starts here
          <WrapperComponent title="View Report">
            <div className={Styles.GenerateReportWrapper}>
              {selectedReportName === reportConstants.LEDGER_REPORT ? (
                <LedgerReportComponent
                  statusData={statusData}
                  filter={filter}
                  setFilter={setFilter}
                  reportList={reportList}
                  handleInputChange={handleInputChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  multiDeal={multiDeal}
                  ledgerCode={ledgerCode}
                  handleChange={handleChange}
                  startDate={startDate}
                  endDate={endDate}
                  ledgerLists={ledgerLists}
                  ledgerCodeOptions={ledgerCodeOptions}
                  setLedgerCode={setLedgerCode}
                  setstartDate={setstartDate}
                  setEndDate={setEndDate}
                  setMultiDeal={setMultiDeal}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  displayedOptions={displayedOptions}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  handleGenerateReport={handleGenerateReport}
                  ledgerStatus={ledgerStatus}
                />
              ) : selectedReportName == reportConstants.POOLWISE_REPORT ? (
                <PoolWiseReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  multiDeal={multiDeal}
                  handleChange={handleChange}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  yearData={yearData}
                  monthList={monthList}
                />
              ) : selectedReportName == reportConstants.VOUCHER_REPORT ? (
                <VoucherReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  SerachReportState={SerachReportState}
                  voucherDetails={voucherDetails}
                  handleVoucherReport={handleVoucherReport}
                  transactionHanlder={transactionHanlder}
                />
              ) : selectedReportName == reportConstants.SOA_REPORT ? (
                <SOAReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  SerachReportState={SerachReportState}
                  handleVariousReports={handleSoaReport}
                  soaDetails={soaDetails}
                  setSoaDetails={setSoaDetails}
                  getFinancialYears={getFinancialYears}
                />
              ) : selectedReportName == reportConstants.ABC_REPORT ? (
                <ABCReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  multiDeal={multiDeal}
                  handleChange={handleChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  yearData={yearData}
                  monthList={monthList}
                />
              ) : selectedReportName == reportConstants.CONSOLIDATED_ACCOUNTING_REPORT ? (
                <ABCReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  multiDeal={multiDeal}
                  handleChange={handleChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  yearData={yearData}
                  monthList={monthList}
                />
              ) : selectedReportName == reportConstants.CONSOLIDATED_SUCCESS_REPORT ? (
                <ABCReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  multiDeal={multiDeal}
                  handleChange={handleChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  yearData={yearData}
                  monthList={monthList}
                />
              ) : selectedReportName == reportConstants.BEFORE_APPROVAL_REPORT ? (
                <ABCReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  multiDeal={multiDeal}
                  handleChange={handleChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  yearData={yearData}
                  monthList={monthList}
                />
              ) : selectedReportName === reportConstants.SYNERGY_EXTRACTED_REPORT ? (
                <SynergyReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  SerachReportState={SerachReportState}
                  status={status}
                  loanNo={loanNo}
                  setLoanNo={setLoanNo}
                  handleVariousReports={handleKanazaReport}
                />
              ) : selectedReportName === reportConstants.TRIAL_BALANCES_REPORT ? (
                <TrialBalancesReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  multiDeal={multiDeal}
                  displayedOptions={displayedOptions}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  handleInputChange1={handleInputChange1}
                  statusData1={statusData1}
                  startDate={startDate}
                  setstartDate={setstartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  trailStatus={trailStatus}
                  CompHeading={ledgerReportHeading}
                />
              ) : selectedReportName === reportConstants.AS_ON_DATE_REPORT ? (
                <AsOnDateReportComponent
                  reportList={reportList}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  handleGenerateReport={handleGenerateReport}
                  reportHeading={reportHeading}
                  previousMonthList={previousMonthList}
                  asOnDateStatus={asOnDateStatus}
                  selectedReportName={selectedReportName}
                />
              ) : (
                //? other reports
                <DefaultReportComponent
                  reportList={reportList}
                  reportHeading={reportHeading}
                  statusData={statusData}
                  handleInputChange={handleInputChange}
                  entityData={entityData}
                  handleEntityChange={handleEntityChange}
                  sourceList={sourceList}
                  entityStatus={entityStatus}
                  multiDeal={multiDeal}
                  handleChange={handleChange}
                  displayedOptions={displayedOptions}
                  setFilter={setFilter}
                  filter={filter}
                  selectedAll={selectedAll}
                  setSelectedAll={setSelectedAll}
                  uploadReportState={uploadReportState}
                  SerachReportState={SerachReportState}
                  status={status}
                  handleGenerateReport={handleGenerateReport}
                  previousMonthList={previousMonthList}
                  yearData={yearData}
                  monthList={monthList}
                  selectedReportName={selectedReportName}
                />
              )}
            </div>
          </WrapperComponent>
        )}
      </Layout>
    </>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance, { API } from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "dealDeatails", isChecked: false },
    { pageName: "template", isChecked: false },
    { pageName: "monthlyCollectionReport", isChecked: false },
    { pageName: "monthlyCollectionReportSummary", isChecked: false },
  ],
  dealName: "",
  dealYear: "",
  collectionMonth: "",
  uploadType: "",
  monthlyCollectionReportFile: null,
  loading: null,
  error: null,
  success: null,
  dealList: [],
  ReportsList: [],
  totalPages: 0,
  singleDealReport: null,
  isDealUploaded: null,
  accountingEntries: null,
  changeStatusLoading: null,
  accountingEntryLoading: null,
  monthlyClosureLoading: null,
  successMsg: null,
  disableQueryandHistory: false,
  ledgerCodes: [],
  remainingMonthlyUploadMonthList: [],
  allBranches: [],
  sortValue: true,
  sortFieldName: null,
  voucher: null,
  voucherData: null,
  soaReport: null,
  yearData: null,
  monthEndData: null,
};

//? Fetching Deal List API
export const fetchDealList = createAsyncThunk(
  "fetchDealList",
  async (rejectWithValue) => {
    try {
      const usersData = await instance.get(
        "/portfolio-service/deal?status=CLOSED&page=1&limit=10000"
        // config
      );
      return usersData?.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
function getYearsFrom(startYear) {
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year.toString());
  }

  console.log({ years });

  return years;
}


//? Fetching Deal List API
export const fetchYearData = createAsyncThunk(
  "fetchYearData",
  async (value, rejectWithValue) => {
    try {
      // const usersData = await instance.get(
      //   `/accounting-service/getReportMonths?dealId=${value.dealId}&reportName=${value.reportName}&entity=${value.entity}`
      // );
      // const res = usersData?.data;
      // console.log({ res });
      // return res;

      const response = {
        data: {
          years: getYearsFrom("2021"),
          months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
      };

      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const fetchSourceDealList = createAsyncThunk(
  "fetchSourceDealList",
  async ({ entity }, { rejectWithValue }) => {
    try {
      const usersData = await instance.get(
        `/portfolio-service/deal?status=CLOSED&page=1&limit=10000&entity=${entity}`
        // config
      );
      return usersData?.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

//? Fetching remaining monthly upload month list API
export const fetchMonthList = createAsyncThunk(
  "fetchMonthList",
  async (value, rejectWithValue) => {
    try {
      const monthData = await instance.get(
        `/accounting-service/getMonthOfNotUploaded?dealId=${value}`
        // config
      );
      return monthData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getMonthUplods = createAsyncThunk(
  "getMonthUplods",
  async ({ dealId, financialYear, page, limit }, rejectWithValue) => {
    try {
      const monthData = await instance.get(
        `/accounting-service/getDealsNotClosed?page=${page - 1 || 0
        }&limit=${limit}&dealId=${dealId}&financialYear=${financialYear}`
        // config
      );
      return monthData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getVoucherDetails = createAsyncThunk(
  "getVoucherDetails",
  async ({ transaction, year }, rejectWithValue) => {
    try {
      const monthData = await instance.get(
        // `/accounting-service/getVoucherReport?transactionId=VPO2_00010870670_VPO2_0123_01`
        `/accounting-service/getVoucherReport?transactionId=${transaction}`
        // config
      );
      return monthData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getSOAReport = createAsyncThunk(
  "getSOAReport",
  async ({ loanNumber, financialYear }, rejectWithValue) => {
    try {
      const soaReportData = await instance.get(
        `/accounting-service/getSoaReport?loanNumber=${loanNumber}&financialYear=${financialYear}`
        // `/accounting-service/getSoaReport?loanNumber=${"05200411008"}`
      );
      return soaReportData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

//? Upload Monthly Collection Report API
export const uploadReport = createAsyncThunk(
  "uploadReport",
  async (
    {
      formData,
      fileName,
      isReUpload,
      reUploadId,
      dealId,
      month,
      year,
      updateOtherData,
    },
    { rejectWithValue }
  ) => {
    console.log({ formData });
    try {
      //utility change
      const uploadData = await instance.post(
        `/utility-service/upload-file`,
        {
          file: formData,
          reUpload: isReUpload,
          reUploadId: reUploadId,
          dealId: dealId,
          month: month,
          uniquecode: "",
          year: year,
          isUploadFile: true,
          serviceName: "accounting-service",
          endpoint: "upload",
          updateOtherData: updateOtherData,
        },

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//? GetReports API
export const getReportList = createAsyncThunk(
  "getReportList",
  async (
    { limit, currentPage, debouncedSearch, sortName, sortOrder, entity },
    rejectWithValue
  ) => {
    console.log({ limit, currentPage, debouncedSearch });
    let response;
    console.log({ sortName, sortOrder });
    try {
      if (sortName && sortOrder) {
        response = await instance.get(
          `/accounting-service/getAllUploads?page=${currentPage}&limit=${limit}&sortField=${sortName}&sortOrder=${sortOrder}&entity=${entity}`,
          {
            headers: {
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return response?.data.data;
      }
      if (debouncedSearch !== "") {
        response = await instance.get(
          `/accounting-service/getAllUploads?page=${currentPage}&limit=${limit}&searchQuery=${debouncedSearch}&entity=${entity}`,
          {
            headers: {
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return response?.data.data;
      }

      response = await instance.get(
        `/accounting-service/getAllUploads?page=${currentPage}&limit=${limit}&entity=${entity}`,
        {
          headers: {
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return response?.data.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

//Get Month-End Deal List 
export const getMonthEndAllDeals = createAsyncThunk(
  "getMonthEndAllDeals",
  async (
    {
      limit,
      currentPage,
      debouncedSearch = undefined,
      filterName = "ALL",
      sortName,
      sortOrder,
      month,
      year,
      entityFilter
    },

    { rejectWithValue }
  ) => {
    // console.log({ limit, currentPage, debouncedSearch, filterName });
    console.log({ sortName, sortOrder, filterName });
    let response;

    try {

      if (sortName && sortOrder) {
        response = await instance.get(
          `/accounting-service/getAllDealList?page=${currentPage}&limit=${limit}${sortName && sortOrder != "" ? `&${sortName}=` + sortOrder : ""}${entityFilter == "ALL" ? "" : "&entity=" + entityFilter}`
        );

        console.log({ originatorsListData: response?.data?.data });

        return response?.data?.data;
      }

      if (debouncedSearch !== undefined && debouncedSearch !== "") {
        response = await instance.get(
          `/accounting-service/getAllDealList?searchQuery=${debouncedSearch}&page=${currentPage}&limit=${limit}&month=${month}&year=${year}`
        );
        return response?.data?.data;
      }

      response = await instance.get(
        `/accounting-service/getAllDealList?page=${currentPage}&limit=${limit}&month=${month}&year=${year}${debouncedSearch && debouncedSearch != ""
          ? "&searchQuery=" + debouncedSearch
          : ""
        }${entityFilter == "ALL" ? "" : "&entity=" + entityFilter}`
      );

      console.log({ originatorsListData: response?.data?.data });

      return response?.data?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

//bulk-approval
export const bulkApproval = createAsyncThunk(
  "bulkApproval",
  async (data, { rejectWithValue }) => {
    // console.log(loanUploadFinalId);
    let response;
    try {
      const checkData = await instance.post(
        `${API}/accounting-service/monthly-upload/bulk-approval`,
        {
          ...data
        },

        {
          headers: {
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      // return response?.data;
      return checkData;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err?.response?.data.message);
    }
  }
);

//? Get Single Report API
export const fetchSingleDealReport = createAsyncThunk(
  "fetchSingleDealReport",
  async (loanUploadFinalId, rejectWithValue) => {
    console.log(loanUploadFinalId);
    let response;
    try {
      response = await instance.get(
        `/accounting-service/getSingleLoanUpload?loanUploadFinalId=${loanUploadFinalId}`,
        {
          headers: {
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);
export const getLedgerCodes = createAsyncThunk(
  "getLedgerCodes",
  async (loanUploadFinalId, rejectWithValue) => {
    let response;
    try {
      response = await instance.get(`/master-service/generalLedger`, {
        headers: {
          Authorization: "Bearer " + isAuthenticated("Token"),
        },
      });

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

export const getAllBranches = createAsyncThunk(
  "getAllBranches",
  async (rejectWithValue) => {
    let response;
    try {
      response = await instance.get(`/accounting-service/getAllBranches`, {
        headers: {
          Authorization: "Bearer " + isAuthenticated("Token"),
        },
      });

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

export const setPurpose = createAsyncThunk(
  "setPurpose",
  async (loanUploadFinalId, rejectWithValue) => {
    let response;
    try {
      response = await instance.post(
        `/master-service/purpose`,
        {
          headers: {
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        },
        {
          name: "Interest On Housing Loan ( Aadhar Da )",
        }
      );
      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

//? Create Accounting Entries API
export const createAccountingReport = createAsyncThunk(
  "createAccountingReport",
  async (loanUploadFinalId, { rejectWithValue }) => {
    console.log(loanUploadFinalId);
    let response;
    try {
      const uploadData = await instance.post(
        `${API}/accounting-service/generateAccountingEntry`,
        {
          loanUploadFinalId: loanUploadFinalId,
        },

        {
          headers: {
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      // return response?.data;
      return uploadData;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err?.response?.data.message);
    }
  }
);

//? Check Deal is already Uploaded or not API
export const checkDealUploaded = createAsyncThunk(
  "checkDealUploaded",
  async ({ month, dealId, isFinalUpload }, rejectWithValue) => {
    console.log({ month, dealId, isFinalUpload });
    let response;
    try {
      response = await instance.get(
        `/accounting-service/checkDealUpload?month=${month}&dealId=${dealId}&isFinalUpload=${isFinalUpload}`
      );

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

//? Final Submit API
export const finalSubmitForApproval = createAsyncThunk(
  "finalSubmitForApproval",
  async (id, { rejectWithValue }) => {
    let response;
    try {
      response = await instance.post("/accounting-service/finalSubmission", {
        id: id,
      });

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err?.response?.data.message);
    }
  }
);

//? Change Status API
export const changeStatus = createAsyncThunk(
  "changeStatus",
  async ({ uniqueCode, Status, isfinalUpload }, { rejectWithValue }) => {
    console.log({ uniqueCode, Status, isfinalUpload });
    let response;
    try {
      response = await instance.post(
        "accounting-service/monthly-upload/changeStatus",
        {
          uniqueCode: uniqueCode,
          status: Status,
          isFinalUpload: isfinalUpload,
          proceedWithUpload: false,
        }
      );

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

//? Change Closure Status API
export const changeClosureStatus = createAsyncThunk(
  "changeClosureStatus",
  async (
    { uniqueCode, Status, isfinalUpload, proceedWithUploadVal },
    { rejectWithValue }
  ) => {
    console.log({ uniqueCode, Status, isfinalUpload });
    let response;
    try {
      response = await instance.post(
        "accounting-service/monthly-upload/changeStatus",
        {
          uniqueCode: uniqueCode,
          status: Status,
          isFinalUpload: isfinalUpload,
          proceedWithUpload: proceedWithUploadVal,
        }
      );

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

// getDownloadLink image api

export const getDownloadImageById = createAsyncThunk(
  "getDownloadImageById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `utility-service/image?id=${id}`
        // config
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Regenerate Entery for Accounting

export const RegenerateEntry = createAsyncThunk(
  "RegenerateEntry",
  async ({ id }, { rejectWithValue }) => {
    let response;
    try {
      response = await instance.post("accounting-service/regenerate-entry", {
        id: id,
      });

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

const UploadReportSlice = createSlice({
  name: "UploadReportSlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
    },
    dealYearAction: (state, { payload }) => {
      console.log("dealyearinstate", payload);
      state.dealYear = payload;
    },
    disableQueryandHistory: (state) => {
      state.disableQueryandHistory = true;
    },
    setSortValue: (state, { payload }) => {
      state.sortValue = payload;
    },
    setSortName: (state, { payload }) => {
      state.sortFieldName = payload;
    },
    enableQueryAndHistory: (state) => {
      state.disableQueryandHistory = false;
    },
    clearUploadStateAction: (state) => {
      state.dealName = "";
      state.collectionMonth = "";
      state.uploadType = "";
      state.monthlyCollectionReportFile = null;
      state.loading = null;
      state.error = null;
      state.success = null;
      state.dealList = [];
      state.ReportsList = [];
      state.totalPages = 0;
      state.singleDealReport = null;
      state.isDealUploaded = null;
      state.accountingEntries = null;
      state.remainingMonthlyUploadMonthList = [];
    },
    clearCheckPages: (state) => {
      state.checkedPages = [
        { pageName: "dealDeatails", isChecked: false },
        { pageName: "template", isChecked: false },
        { pageName: "monthlyCollectionReport", isChecked: false },
        { pageName: "monthlyCollectionReportSummary", isChecked: false },
      ];
    },

    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    monthlyCollectionReportAction: (state, { payload }) => {
      state.monthlyCollectionReportFile = payload.monthlyCollectionReportFile;
    },

    clearMonthlyCollectionReportAction: (state) => {
      state.monthlyCollectionReportFile = null;
    },
    dealDetailsAction: (state, { payload }) => {
      state.dealName = payload.dealName;
      state.collectionMonth = payload.collectionMonth;
      state.uploadType = payload.uploadType;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
  },
  extraReducers: (builder) => {
    // DealList
    builder.addCase(fetchDealList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDealList.fulfilled, (state, { payload }) => {
      console.log(payload.data.data);
      state.loading = false;
      state.dealList = payload.data.data;
    });
    builder.addCase(fetchDealList.rejected, (state, { payload }) => {
      state.loading = false;
      state.dealList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //Source DealList
    builder.addCase(fetchSourceDealList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSourceDealList.fulfilled, (state, { payload }) => {
      console.log(payload.data.data);
      state.loading = false;
      state.dealList = payload.data.data;
    });
    builder.addCase(fetchSourceDealList.rejected, (state, { payload }) => {
      state.loading = false;
      state.dealList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //Upload Report
    builder.addCase(uploadReport.pending, (state) => {
      state.loading = true;
      state.disableQueryandHistory = true;
    });
    builder.addCase(uploadReport.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state[payload.fileName] = payload.data;
      state.disableQueryandHistory = false;
    });
    builder.addCase(uploadReport.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = payload;
      state.disableQueryandHistory = false;
    });

    //ListOfReports
    builder.addCase(getReportList.pending, (state) => {
      state.loading = true;
      state.originatorsList = null;
    });
    builder.addCase(getReportList.fulfilled, (state, { payload }) => {
      console.log({ payload });

      state.loading = false;
      state.ReportsList = payload;
      state.success = true;
      state.totalPages = payload?.page;
    });
    builder.addCase(getReportList.rejected, (state, { payload }) => {
      state.loading = false;
      state.originatorsList = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //ListOfDeals
    builder.addCase(getMonthEndAllDeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMonthEndAllDeals.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;

      state.allDeals = payload?.data;
      state.totalPages = payload?.totalPages;
      // state.allDeals = payload?.data?.data?.data;
    });
    builder.addCase(getMonthEndAllDeals.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });

    //Bulk-approval
    builder.addCase(bulkApproval.pending, (state) => {
      state.accountingEntryLoading = true;
    });
    builder.addCase(bulkApproval.fulfilled, (state, { payload }) => {
      console.log({ payload });
      // state.accountingEntryLoading = false;
      state.successMsg = payload.data.message;
      state.success = true;
    });
    builder.addCase(bulkApproval.rejected, (state, { payload }) => {
      console.log("error paylod", payload);
      // state.accountingEntryLoading = false;
      state.error = payload;
    });

    //SingleDealReport
    builder.addCase(fetchSingleDealReport.pending, (state) => {
      state.loading = true;
      state.singleDealReport = null;
    });
    builder.addCase(fetchSingleDealReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.singleDealReport = payload?.data;
      state.success = true;
    });
    builder.addCase(fetchSingleDealReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.singleDealReport = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //AccountingReport
    builder.addCase(createAccountingReport.pending, (state) => {
      state.accountingEntryLoading = true;
    });
    builder.addCase(createAccountingReport.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.accountingEntryLoading = false;
      state.successMsg = payload.data.message;
      state.success = true;
    });
    builder.addCase(createAccountingReport.rejected, (state, { payload }) => {
      console.log("error paylod", payload);
      state.accountingEntryLoading = false;
      state.error = payload;
    });

    // Remaining Monthly Upload Month List
    builder.addCase(fetchMonthList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMonthList.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.remainingMonthlyUploadMonthList = payload.data.data;
      state.success = true;
    });
    builder.addCase(fetchMonthList.rejected, (state, { payload }) => {
      console.log("error paylod", payload);
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(getMonthUplods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMonthUplods.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.monthEndData = payload.data.data;
      state.success = true;
    });
    builder.addCase(getMonthUplods.rejected, (state, { payload }) => {
      console.log("error paylod", payload);
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(getVoucherDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVoucherDetails.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.voucher = payload.data.data.data;
      state.voucherData = payload.data.data;
      state.success = true;
    });
    builder.addCase(getVoucherDetails.rejected, (state, { payload }) => {
      console.log("error paylod", payload);
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(getSOAReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSOAReport.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.soaReport = {
        ...payload.data.data.data,
        fromDate: payload.data.data.fromDate || "",
        toDate: payload.data.data.toDate || "",
      };
      // state.soaReport = { ...payload.data.data.data, this: "shubham" };
      state.success = true;
    });
    builder.addCase(getSOAReport.rejected, (state, { payload }) => {
      console.log("error paylod", payload);
      state.loading = false;
      state.error = payload;
    });

    //Change Status
    builder.addCase(changeStatus.pending, (state) => {
      state.changeStatusLoading = true;
    });
    builder.addCase(changeStatus.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.changeStatusLoading = false;
      state.success = true;
    });
    builder.addCase(changeStatus.rejected, (state, { payload }) => {
      state.changeStatusLoading = false;
      state.error = payload;
    });

    //Change Clousre Status
    builder.addCase(changeClosureStatus.pending, (state) => {
      state.changeClosureStatus = true;
    });
    builder.addCase(changeClosureStatus.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.changeClosureStatus = false;
      state.success = true;
    });
    builder.addCase(changeClosureStatus.rejected, (state, { payload }) => {
      state.changeClosureStatus = false;
      state.error = payload;
    });

    //Checking for deal already uploaded or not
    builder.addCase(checkDealUploaded.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkDealUploaded.fulfilled, (state, { payload }) => {
      console.log({ payload });

      state.loading = false;
      state.isDealUploaded = payload.data;
      state.success = true;
    });
    builder.addCase(checkDealUploaded.rejected, (state, { payload }) => {
      state.loading = false;
      state.isDealUploaded = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //Submit for approval
    builder.addCase(finalSubmitForApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(finalSubmitForApproval.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.success = true;
    });
    builder.addCase(finalSubmitForApproval.rejected, (state, { payload }) => {
      console.log("errrrrrrrrrr", payload);
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getLedgerCodes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLedgerCodes.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.ledgerCodes = payload.data.data;
    });
    builder.addCase(getLedgerCodes.rejected, (state, { payload }) => {
      console.log("errrrrrrrrrr", payload);
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getAllBranches.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBranches.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.allBranches = payload.data;
    });
    builder.addCase(getAllBranches.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(getDownloadImageById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDownloadImageById.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
    });
    builder.addCase(getDownloadImageById.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Regenerate Entery for Accounting
    builder.addCase(RegenerateEntry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(RegenerateEntry.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.success = true;
    });
    builder.addCase(RegenerateEntry.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(fetchYearData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchYearData.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.yearData = payload.data;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(fetchYearData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  clearSucessAndErrorAction,
  monthlyCollectionReportAction,
  dealDetailsAction,
  pageStatusAction,
  clearMonthlyCollectionReportAction,
  clearUploadStateAction,
  clearCheckPages,
  deleteDoc,
  disableQueryandHistory,
  enableQueryAndHistory,
  dealYearAction,
  setSortName,
  setSortValue,
} = UploadReportSlice.actions;

export const uploadReportReducer = UploadReportSlice.reducer;

import axios from "axios";
import { redirectToLoginAction } from "../redux/app/appSlice";
import Paths from "../components/constants/Path";
import { showErrorAlertAction } from "../redux/app/appSlice";
import { store } from "../redux/store";
import { useHistory } from "react-router-dom";
export const ENV = process.env.REACT_APP_ENV;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const API = process.env.REACT_APP_API_URL;

function errorMessage(data) {
  let errors = "";
  console.log(Object.keys(data));
  Object.keys(data).map((key) => {
    errors += `${data[key]}, `;
  });
  return errors;
}

const { dispatch } = store;
// axioss instance for API calls added in file
const instance = axios.create({
  baseURL: API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

instance.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("Token"));
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["apikey"] = API_KEY;
  config.headers["environment"] = ENV;
  config.headers["active-role"] = localStorage.getItem("activeRole");
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      dispatch(redirectToLoginAction());
    }

    if (error.response.status === 504) {
      var history = useHistory();
      history.push(Paths.PHASE1HOME);
    }
    if (error.response.status === 503) {
      dispatch(showErrorAlertAction("Something went wrong"));
    }
    if (error.response.status === 502) {
      dispatch(showErrorAlertAction("Something went wrong"));
    }
    if (error.response.status === 400) {
      if (error?.response?.data?.data?.isExist) {
        return error;
      }
      if (
        error?.response?.data?.message ==
        "You can not create originator without spocs"
      ) {
        dispatch(showErrorAlertAction(errorMessage(error.response.data)));
      }
      return error?.response;
    }
    if (error.response.status !== 400) {
      return Promise.reject(error);
    }
  }
);

export default instance;
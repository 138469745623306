import React, { useCallback, useEffect, useState } from "react";
import WrapperComponent from "../../components/Wrapper/WrapperDocument";
import { Grid, Button } from "@mui/material";
import Documents from "./Documents";
import { Colors } from "../../theme/theme";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { uploadDealDocuments } from "../../redux/Deals/dealSlice";
import { showAlertAction } from "../../redux/app/appSlice";
import { isAuthenticated } from "../../helper/AuthHelper";
import instance from "../../API/BaseUrl";

const staticData = [
  {
    key: "supportingDocuments",
    name: "Upload Supporting Documents",
    values: [
      "Disbursement Request Letter",
      "Management Undertaking",
      "Pool Credit Compliance CA Certificate",
      "Cancelled Cheque",
      "RBI Annexure",
      "Others",
    ],
  },
  {
    key: "legalDocuments",
    name: "Upload Legal Documents",
    values: [
      "Deed Of Assignment",
      "Servicing Agreement",
      "Power Of Attorney 1",
      "Power Of Attorney 2",
      "Signed Termsheet",
      "Others",
    ],
  },
  {
    key: "lossEstimateReports",
    name: "Upload Loss Estimate Reports",
    values: ["LE Reports", "Others"],
  },
  {
    key: "termsheets",
    name: "Upload Signed Term Sheet",
    values: ["Term Sheet", "Others"],
  },
  {
    key: "otherDocuments",
    name: "Upload Other Reports",
    values: [
      "Finalised Pool",
      "CIBIL Scrub Report",
      "Entity Details",
      "Approval Memo",
      "KYC Report",
      "Others",
    ],
  },
];

export default function DocumentForm() {
  const dispatch = useDispatch();

  const params = useParams();

  const [selectedOptions, setSelectedOptions] = useState({
    supportingDocuments: ["", ""],
    legalDocuments: ["", ""],
    lossEstimateReports: ["", ""],
    termsheets: [""],
    otherDocuments: ["", ""],
  });

  const [formData, setFormData] = useState({
    supportingDocuments: {},
    legalDocuments: {},
    lossEstimateReports: {},
    termsheets: {},
    otherDocuments: {},
  });

  const [dealDocuments, setDealDocuments] = useState([]);

  useEffect(() => {
    instance
      .get(`/portfolio-service/getDealDocuments?dealId=${params?.dealId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + isAuthenticated("Token"),
        },
      })
      .then((res) => {
        console.log(res?.data?.data);
        setDealDocuments(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.dealId]);

  const getFileNameFromId = (id) => {
    return dealDocuments?.files?.filter((item) => item._id === id)?.[0]
      ?.filename;
  };

  useEffect(() => {
    const newFormData = {
      ...formData,
    };

    const newSelectedOptions = {
      ...selectedOptions,
    };

    let docCount = 0;

    // Loop through the keys of formData
    for (const key in formData) {
      if (dealDocuments[key]) {
        // Create an array of objects with the desired structure
        newFormData[key] = dealDocuments[key].reduce((acc, item) => {
          const fileData = item.values.map((fileId) => ({
            fileId,
            fileName: getFileNameFromId(fileId),
            fileSize: 0,
          }));
          acc[item.key] = fileData;
          docCount += fileData?.length;
          return acc;
        }, {});

        // Create an array of strings with the desired structure

        const data = dealDocuments[key].map((item) => {
          if (
            staticData.find((sd) => sd.key === key)?.values.includes(item.key)
          )
            return item.key;
          else return "Others";
        });

        if (data.length > 0) {
          newSelectedOptions[key] = data;
        }
      }
    }
    if (docCount !== 0) {
      setFormData(newFormData);
      setSelectedOptions(newSelectedOptions);
    }
  }, [dealDocuments, params.dealId]);

  const finalData = useCallback(() => {
    let temp = {};

    Object.keys(formData).forEach((key) => {
      temp[key] = [];

      Object.keys(formData[key]).forEach((key2) => {
        if (
          formData[key][key2]
            .map((item) => item?.fileId)
            .filter((item) => (item ? true : false)).length > 0
        )
          temp[key].push({
            key: key2,
            values: formData[key][key2].map((item) => item?.fileId),
          });
      });
    });

    return temp;
  }, [formData]);

  function onClickHandler() {
    dispatch(
      uploadDealDocuments({ ...finalData(), dealId: params.dealId })
    ).then((res) => {
      dispatch(showAlertAction(res?.payload?.data?.message));
    });

    // go back
    // eslint-disable-next-line no-restricted-globals
    history.back();
  }

  return (
    <div style={{ paddingTop: "14px" }}>
      {staticData.map((sd, idx) => (
        <WrapperComponent
          key={sd.key}
          title={sd.name}
          showButton={true}
          onClickButton={() => {
            setSelectedOptions((prev) => {
              return { ...prev, [sd.key]: [...prev[sd.key], ""] };
            });
          }}
        >
          <Grid container spacing={4}>
            {selectedOptions[sd.key]?.map((item, idx2) => (
              <Documents
                gridWidth={sd.key === "termsheet" ? 12 : 6}
                key={sd.key + idx2}
                options={sd.values}
                index={idx2}
                ids={formData}
                setIds={setFormData}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                selectedValue={selectedOptions[sd.key][idx2]}
                section={sd.key}
                docKey={Object?.keys(formData[sd.key])[idx2]}
              />
            ))}
          </Grid>
        </WrapperComponent>
      ))}

      <div
        style={{
          width: "100%",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          paddingRight: "20px",
          paddingTop: "20px",
          display: "flex",
          height: "60px",
          paddingBottom: "10px",
          background: " #ffffff",
          boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: Colors.piramal_orange,
            color: Colors.white,

            textTransform: "none",
            "&:hover": {
              backgroundColor: Colors.piramal_orange,
            },
            "&:disabled": {
              backgroundColor: "#FAC3B3",
              color: "white",
            },
          }}
          type="submit"
          onClick={onClickHandler}
          disabled={
            !(
              finalData()?.supportingDocuments?.length > 0 ||
              finalData()?.legalDocuments?.length > 0 ||
              finalData()?.lossEstimateReports?.length > 0 ||
              finalData()?.termsheets?.length > 0 ||
              finalData()?.otherDocuments?.length > 0
            )
          }
        >
          Upload Documents
        </Button>
      </div>
    </div>
  );
}

import Logo from "../../assets/images/Logo.png";
import { useEffect, useState } from "react";
import Styles from "./Header.module.css";
import { useHistory } from "react-router-dom";
import Paths from "../constants/Path";
import "./dropDown.css";

const Header = ({ userName }) => {
  let history = useHistory();
  let userData = JSON.parse(localStorage.getItem("UserData"));
  let name = userData.name.split(" ");
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("activeRole")
  );

  console.log("userDataList", userData?.roles?.sort());

  console.log("userDataList",userData?.roles?.sort());

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "Originator") {
      window.location.assign("/originatorhome");
    } else {
      window.location.assign("/dashboard");
    }
  };

  useEffect(() => {
    localStorage.setItem("activeRole", selectedValue || userData.roles[0]);
    window.dispatchEvent(new Event("storage"));
  }, [selectedValue]);

  return (
    <div className={Styles.headerWrapper}>
      <div className={Styles.header}>
        <div className={Styles.logoWrapper}>
          <div className={Styles.imageWrapper}>
            <img src={Logo} alt="logo" />
          </div>
          <p className={Styles.logoName}>Swayam</p>
        </div>

        <div className={Styles.profileWrapper}>
          <div className={Styles.profileIcon}>
            <p>{userName[0]}</p>
          </div>

          <div className={Styles.profileName}>
            <p>{name[0] + `( ${selectedValue || userData.roles[0]}  )`}</p>
          </div>
          {userData?.roles.length != 1 ? (
            <form>
              <div className="custom-dropdown">
                {/* <label for="cars">Choose a car:</label> */}
                <select
                  id="cars"
                  name="cars"
                  className="dropdown-select"
                  value={"Change Role"}
                  onChange={handleDropdownChange}
                >
                  <option value="">Change Role</option>
                  {userData?.roles?.sort().map((user, index) => (
                    <option value={user}>{user}</option>
                  ))}
                </select>
                <span className="arrow">&#9660;</span>
              </div>
            </form>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

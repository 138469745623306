import React from "react";
import {
  Box,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Select,
  Grid,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import { bulkApproval, getMonthEndAllDeals, getReportList } from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import { showAlertAction, showErrorAlertAction } from "../../../redux/app/appSlice";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import MonthEndDealTable from "../../../components/Table/Phase2/MonthEndDealTable";
import Styles from "../../../components/Modal/Modal.module.css"
import OutlineButton from "../../../components/Buttons/OutlineButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import { GenerateReportMenuProps } from "../SearchReports/SearchReports";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { clearSucessAndErrorAction } from "../../../redux/Phase2/SearchReports";

const ListOfMonthEndDeals = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("ALL")
  const [totalPageNumber, setTotalPageNumber] = useState(1)
  const [reportList, setReportsList] = useState("")
  const [dealId, setDealId] = useState([])
  const [previousMonthList, setPreviousMonthList] = useState([
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ]);

  const [yearList, setYearList] = useState([
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ]);

  const [statusData, setStatusData] = useState({
    transactionMonth: "",
    year: "",
  });

  
  let dispatch = useDispatch();

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setStatusData({ ...statusData, [name]: value });
    dispatch(clearSucessAndErrorAction());
  };

  //Using debounce Hook for search
  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    setFilterName(name);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);

  useEffect(() => {
    if(statusData?.transactionMonth && statusData?.year){
      dispatch(getMonthEndAllDeals({ limit, currentPage, month: statusData?.transactionMonth, year: statusData?.year, debouncedSearch, entityFilter: filterName })).then(
        (res) => {
          // Check if payload is an empty object or not
            if (res?.payload && Object.keys(res.payload).length > 0) {
              setReportsList(res.payload?.data);
              setTotalPageNumber(res?.payload?.totalPages);

            } else {
              // Handle the case when payload is empty or not present
              setReportsList([]);  // Set reports list to an empty array
              setTotalPageNumber(0);  // Set total pages to 0
            }
        }).catch((error) => {
          // Handle any errors that occurred during the dispatch
          console.error("Error fetching deals:", error);
          // Optionally, you can set state to indicate an error or fallback
          setReportsList([]);
          setTotalPageNumber(0);
        });
    }
    
  }, [currentPage, debouncedSearch, filterName, statusData]);

  const filterList = [
    {
      id: "0",
      name: "ALL",
    },
    {
      id: "1",
      name: "DHFL",
    },

    {
      id: "2",
      name: "PEL",
    },
    {
      id: "3",
      name: "PCHFL",
    },

  ];


  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage == totalPageNumber) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"))
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  // Previous Page Handler Function for Pagination ends here

  //Breadcrumb Navigation
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const Placeholder = ({ children }) => {
    return <div className={Styles.GenerateReportPlaceholder}>{children}</div>;
  };

  const MenuProps = {
    PaperProps: {
      style: GenerateReportMenuProps,
    },
  };

  const handleBulkApproval = (buttonType) => (event) => {
    if(dealId?.length == 0){
      dispatch(showErrorAlertAction("Please Select Any Deal"));
    }else{
      let data = {
        dealId: dealId,
        month: statusData?.transactionMonth,
        year: statusData?.year,
        type:buttonType
      }
      dispatch(bulkApproval(data)).then((res) => {
        dispatch(showAlertAction(res?.payload?.data?.message))
      })
    }
};

  return (
      <Layout componentName={null} breadCrumbs={breadCrumbs}>
        <Box
          backgroundColor="white"
          minHeight="144px"
          maxHeight="auto"
          minWidth="auto"
          maxWidth="auto"
          boxShadow={4}
          borderRadius={2}
          sx={{ p: 2, m: 2 }}
        >
          <>
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="space-between"
              paddingBottom="12px"
              display="flex"
            >
              <Typography fontSize="16px" fontWeight="700" paddingBottom={1}>
                List of Deals
              </Typography>
            </Stack>

            <Divider></Divider>

            <div
              style={{
                padding: "16px",
                justifyContent: "space-between",
                alignItems: "space-between",
                display: "flex",
              }}
            >

              <div>
                {filterList.map((item, index) => {
                  console.log(item, index);
                  return (
                    <>
                      <StyledButton
                        id={item.id}
                        variant={
                          filterName === item.name ? "contained" : "outlined"
                        }
                        sx={{
                          color: filterName === item.name ? "white" : "black",
                          backgroundColor:
                            filterName === item.name ? "#365069" : "white",
                          borderColor: "black",
                          mr: 1,
                        }}
                        onClick={() => filterHandler(item.name)}
                      >
                        {item.name}
                      </StyledButton>
                    </>
                  );
                })}
              </div>
              <TextField
                placeholder="Search"
                autoComplete="off"
                value={search}
                variant="standard"
                size="small"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>

            <Divider></Divider>

            <div className={Styles.btnTopDiv}>
              <div style={{display:"flex"}}>
                <Grid item xs={4}>
                  <TextWrapperComponent text="Transaction Month">
                    <Select
                      IconComponent={ExpandMoreIcon}
                      value={statusData?.transactionMonth}
                      onChange={handleInputChange("transactionMonth")}
                      displayEmpty
                      size={"small"}
                      variant="standard"
                      fullWidth
                      MenuProps={MenuProps}
                      renderValue={
                        statusData.transactionMonth !== ""
                          ? undefined
                          : () => <Placeholder>Select Month</Placeholder>
                      }
                    >
                      {previousMonthList &&
                        previousMonthList.map((data) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        ))}
                    </Select>
                  </TextWrapperComponent>
                </Grid>
                <Grid item xs={4} sx={{paddingLeft:"39px"}}>
                  <TextWrapperComponent text="Transaction Year">
                    <Select
                      IconComponent={ExpandMoreIcon}
                      value={statusData?.year}
                      onChange={handleInputChange("year")}
                      displayEmpty
                      size={"small"}
                      variant="standard"
                      fullWidth
                      MenuProps={MenuProps}
                      renderValue={
                        statusData.year !== ""
                          ? undefined
                          : () => <Placeholder>Select Year</Placeholder>
                      }
                    >
                      {yearList &&
                        yearList.map((data) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        ))}
                    </Select>
                  </TextWrapperComponent>
                </Grid>
              </div>
              <div className={Styles.btnMidDiv}>
                <div className={Styles.btnLowerDiv} >
                  <OutlineButton
                    showIcon={false}
                    text={"Generate Accounting Entry"}
                    onClickHandler={handleBulkApproval('ACCOUNTING-ENTRY')}
                  />
                </div>
                <PrimaryButton
                  showIcon={false}
                  text={"Month End"}
                  onClickHandler={handleBulkApproval('MONTH-END')}
                  width="60px"
                // isDisable={isDisable}
                />
              </div>
            </div>

            {statusData?.transactionMonth && statusData?.year ? (
              
            <Box>
              {/* Deal Table Component */}
              <MonthEndDealTable
                data={reportList}
                page={currentPage}
                header={
                  [
                    "Deal Code",
                    "Deal Name",
                    "Total Uploads",
                    "Entity",
                    "Accounting Entry Status",
                    "Month End Status",
                  ]
                }
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                setDealId={setDealId}
                dealId={dealId}
                totalRecords={totalPageNumber}
              ></MonthEndDealTable>
            </Box>
            ) : (
              <>
                <Typography sx={{m: "12px", lineHeight: "3.5"}}>
                  Please Select Transaction Month & Year
                </Typography>
              </>
            )} 
          </>
        </Box>
      </Layout>
  );
};

export default ListOfMonthEndDeals;

import { Typography } from "@mui/material";
import React from "react";
import ViewDownloadDeleteComp from "./L2/Phase2DownloadComp";
import { DocumentHeader } from "./DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";

export default function LabelWithDownload({
  label,
  fileId,
  fileName,
  withHeader = false,
  withPadding = false,
}) {
  // Get Extension from fileName
  const getExtension = (fileName) => {
    let extension = fileName?.split(".").pop();
    return extension;
  };

  // File extension extraction
  const fileExtension = getExtension(fileName);

  const fileNames =
    label === "Finalized Pool"
      ? label + "." + getExtension(fileName)
      : !fileName
      ? label + "." + getExtension(fileName)
      : fileName;

  return (
    <>
      {withHeader && (
        <DocumentHeader
          title={label}
          startIcon={
            <DescriptionIcon
              sx={{ color: "#5E7387", width: "20px", height: "20px" }}
            />
          }
        />
      )}

      {fileId && (
        <>
          <div
            style={{
              padding: withHeader || withPadding ? "24px" : "0px",
            }}
          >
            <Typography
              fontSize="14px"
              color="#808080"
              fontWeight="400"
              paddingBottom="10px"
            >
              {label}
            </Typography>
            <ViewDownloadDeleteComp
              showEye={false}
              fileName={fileNames}
              id={fileId}
              fileExtension={fileExtension}
            />
          </div>
        </>
      )}
    </>
  );
}

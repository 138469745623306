import React, { useEffect, useState } from "react";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPageStatusAction,
  pageStatusAction,
  submitKYCVerificationReport,
} from "../../../redux/Auditor/kycApproveRejectSlice";
import Paths from "../../constants/Path";
import { useHistory } from "react-router-dom";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import BasicModal from "../../Modal/Modal";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import {
  getAllDeals,
  getSingleDealDetails,
} from "../../../redux/Deals/dealSlice";
import { deleteDoc } from "../../../redux/originator/originatorSlice";
import EntityDetailsDownload from "./EntityDetailsDownload";
import Loader from "../../../utils/Loader";
import { setEntityFile } from "../../../redux/Entity/entitySlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EntityDeatilsComp = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const entityState = useSelector((state) => state.entity);
  const originatorState = useSelector((state) => state.originator);
  const [open, setOpen] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [state, setState] = useState(null);
  const [loanFile, setLoanFile] = useState(null);
  const dealState = useSelector((state) => state.deal);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const params = useParams();
  const onClickHandlerToDealsPage = () => {
    setOpen(true);
    dispatch(pageStatusAction({ pageName: "verificationDoc" }));
  };

  const handleClosureStatus = (e) => {
    e.preventDefault();

    if (state == undefined) {
      dispatch(showErrorAlertAction("Please select file !"));
      setOpen(false);
      return;
    }
    dispatch(
      setEntityFile({
        file: originatorState?.EntityDetails?.fileId,
        dealId: params.dealId,
      })
    ).then((res) => {
      if (res?.payload?.status == 201) {
        return history.push(
          `/entityloandeatils/${dealState?.filterdata?._id || dealState?.filterdata?._id
          }/details`
        );
      }
    });

    // history.push(Paths.EntityDealList);
  };

  console.log({ open });
  console.log({ entityState });

  return (
    <>
      {entityState.entitydata == null ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title="Entity Details Template"
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              ></DescriptionIcon>
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <EntityDetailsDownload
              fileName={entityState?.entitydata?.file?.filename}
              size={entityState?.entitydata?.file?.length}
              id={entityState?.entitydata?.file?._id}
            ></EntityDetailsDownload>
          </div>

          {userData?.roles.length != 1 ? (<></>) : (<>
            <DocumentHeader
              title={"Entity Details"}
              startIcon={
                <DescriptionIcon
                  sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                />
              }
            ></DocumentHeader>
            <div style={{ padding: "10px", width: "auto" }}>
              <UploadTemplate
                docName="EntityDetails"
                setdata={setState}
                setLoanFile={setLoanFile}
              />
            </div>

            <TabFooter
              label="Upload"
              isBtnDisable={originatorState?.EntityDetails == null}
              endIcon={<DoneIcon />}
              onclickHandler={onClickHandlerToDealsPage}
            />
          </>)}



          <BasicModal
            open={open}
            setOpen={setOpen}
            title="Do you want to Upload this File?"
            positiveText="Yes"
            negativeText="No"
            positiveOnClickHandler={handleClosureStatus}
            setloading={setMsgLoading}
          ></BasicModal>
        </>
      )}
    </>
  );
};

export default EntityDeatilsComp;

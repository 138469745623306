import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { API_KEY, ENV } from "../../API/BaseUrl";
import Styles from "./Modal.module.css"
import { useEffect } from "react";

function ViewModal({ id, open, onClose }) {
    useEffect(() => {
        if (open) {
          // Hide the element with id 'file-name' when the modal is open
          const fileNameElement = document.getElementById('file-name');
          if (fileNameElement) {
              fileNameElement.style.display = 'none';
          }

          // Create a style element and append it to the head
          const style = document.createElement('style');
          style.innerHTML = `
            #pdf-download {
              display: none !important;
            }
          `;
          document.head.appendChild(style);
          // Cleanup the style element when the component unmounts or modal closes
          return () => {
            document.head.removeChild(style);
            if (fileNameElement) {
              fileNameElement.style.display = ''; // Revert to original display property
            }
          };
        }
      }, [open]);
      if (!open) return null; // If modal is not open, render nothing

    const headers = {
        environment: ENV,
        apikey: API_KEY,
    };

    const docs = [
        {
            uri: `https://apisit.piramalfinance.com/swayam/utility-service/image?id=${id}`,
        },
    ];

    return (
        <div className={Styles.modalOverlay}  onClick={onClose} >
            <div className={Styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    requestHeaders={headers}
                />
            </div>
        </div>
    );
}
export default ViewModal;
import React from "react";
import styles from "./EntityDetailsDownload.module.css";
import Download from "../../../assets/icons/download.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { downloadImageReports } from "../../../redux/Phase2/SearchReports";
import { getFileSize } from "../../../utils/helper";

const EntityDetailsDownload = ({ fileName, id, size }) => {
  const dealState = useSelector((state) => state.deal);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  // let id = dealState?.loanUploadTemplate?.fileData?._id
  const dispatch = useDispatch();

  const getExtension = (fileName) => {
    let extension = fileName?.split(".").pop();
    return extension;
  };  

  const fileExtension = getExtension(fileName);

  const fileExt = [
    "xlsx", "xls", "docx", "ppt", "pptx"
  ]

  // Check if the file extension is 'xlsx'
  const isDisabled = fileExt.includes(fileExtension)
    && userData?.roles.length != 1
    && fileName !== "Finalized Pool.xlsx";

  const downloadExcel = ({ id, fileName }) => {
    dispatch(downloadImageReports({ id, fileName }));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.filenameWrapper}>
          <CheckCircleIcon
            sx={{
              color: "#41C66E",
              paddingLeft: "8px",
            }}
          ></CheckCircleIcon>
          <p>{fileName}</p>
        </div>

        <div>
          <div onClick={isDisabled ? () => { } : () => downloadExcel({ id, fileName })}>
            <img
              className={styles.singleIconWrapper}
              style={{
                filter: isDisabled
                  ? "grayscale(100%) brightness(0.5)" // Disabled effect
                  : "none", // Normal state
                cursor: isDisabled ? "not-allowed" : "pointer", // Disabled cursor
              }}
              src={Download}
            />
          </div>
        </div>
        <div className={styles.break}>{getFileSize(size)}</div>
      </div>
    </>
  );
};

export default EntityDetailsDownload;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "basicInformation", isChecked: false },
    { pageName: "otherDetails", isChecked: false },
    { pageName: "assignSPOC", isChecked: false },
    { pageName: "kycDocuments", isChecked: false },
    { pageName: "documents", isChecked: false },
  ],
  originatorName: "",
  originatorType: "",
  spocs: [],
  originatorIds: [],
  panCard: null,
  panNumber: "",
  gstNumbers: [],
  gstCertificate: null,
  incorporationCertificate: null,
  cancelledCheque: null,
  rbiRegisterationCertificate: null,
  leiCard: null,
  leiNumber: "",
  portfolioPerformanceMetrics: [],
  quaterlyPerformance: [],
  financialPerformanceMetrics: [],
  originatorTypeList: [],
  loading: null,
  error: null,
  success: null,
  originatorsList: [],
  foundOriginatorList: false,
  isKYCUploaded: false,
  quaterlyPer: null,
  fetchOriginatorLoding: false,
  singleOriginatorData: null,
  totalPages: 0,
  pageLimit: 0,
  pageNumber: 0,
  originatorIdsData: [],
  originatorProfileId: null,
  originatorProfileName: null,
  sortOrgName: null,
  sortOrgValue: true,
  dealRedirection: false,
  duplicateOriginator: {},
  allgstCertificate: null,
};

export const getOriginatorList = createAsyncThunk(
  "getOriginatorList",
  async (
    { limit, currentPage, debouncedSearch, filterName, sortName, sortOrder },
    rejectWithValue
  ) => {
    let response;
    try {
      if (sortName && sortOrder) {
        console.log(sortName, sortOrder);
        response = await instance.get(
          `/portfolio-service/originator?page=${currentPage}&limit=${limit}${
            sortName && sortOrder != ""
              ? `&sort=${sortOrder == 1 ? sortName : `-${sortName}`}`
              : ""
          }`
        );
        console.log({ originatorsListData: response?.data?.data });

        return response?.data?.data;
      }
      response = await instance.get(
        `/portfolio-service/originator?page=${currentPage}&limit=${limit}${
          filterName.toUpperCase() == "ALL"
            ? ""
            : "&status=" + filterName.toUpperCase().replace(" ", "_")
        }${debouncedSearch != "" ? "&name=" + debouncedSearch : ""}`
      );

      console.log({ originatorsListData: response?.data?.data });

      return response?.data?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

export const getOriginatorDetails = createAsyncThunk(
  "getOriginatorDetails",
  async (originatorId, rejectWithValue) => {
    let response;
    try {
      response = await instance.get(
        `/portfolio-service/originator/getSingleOriginator?id=${originatorId}`
      );

      console.log({ originatorData: response?.data?.data });

      return response?.data?.data;
    } catch (err) {
      // console.log({ err });
      return rejectWithValue(err);
    }
  }
);

export const setOriginatorStatus = createAsyncThunk(
  "setOriginatorStatus",
  async (values, thunkApi) => {
    let data = JSON.stringify({ ...values });
    try {
      const usersData = await instance.put(
        `/portfolio-service/originator/updateStatus`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return usersData;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchOriginatorType = createAsyncThunk(
  "fetchOriginatorsType",
  async (rejectWithValue) => {
    try {
      const usersData = await instance.get(
        "/master-service/originatortype"
        // config
      );
      return usersData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const uploadKYCDoc = createAsyncThunk(
  "uploadKYCDoc",
  async ({ formData, fileName }, rejectWithValue) => {
    // console.log({ formData, fileName });
    try {
      const uploadData = await instance.post(
        "/utility-service/upload-file",
        {
          file: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const uploadDocuments = createAsyncThunk(
  "uploadDocuments",
  async ({ formData, fileName }, rejectWithValue) => {
    // console.log({ formData, fileName });
    try {
      const uploadData = await instance.post(
        "/utility-service/upload-file",
        {
          file: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const setGstDetails = createAsyncThunk(
  "setGstDetails",
  async (data, thunkAPI) => {
    console.log(data);
    try {
      const originatorResponse = await instance.post(
        "/portfolio-service/originator",
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );

      // console.log({ originatorResponse });
      return originatorResponse;
    } catch (err) {
      console.log({ errresponse: err?.response?.data });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const submitOriginatorDetails = createAsyncThunk(
  "submitOriginatorDetails",
  async (data, rejectWithValue) => {
    try {
      const originatorResponse = await instance.post(
        "/portfolio-service/originator",
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );

      // console.log({ originatorResponse });
      return originatorResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkDeDup = createAsyncThunk(
  "checkDeDup",
  async (data, rejectWithValue) => {
    try {
      const originatorResponse = await instance.post(
        "/portfolio-service/originator?dedupeCheck=true",
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );

      // console.log({ originatorResponse });
      return originatorResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadQuatarlyDoc = createAsyncThunk(
  "uploadQuatarlyDoc",
  async (values, rejectWithValue) => {
    console.log({ values });
    const { quaterlyPerformanceId, originatorId } = values;
    try {
      if (quaterlyPerformanceId !== undefined) {
        const response = await instance.put("/portfolio-service/originator", {
          id: originatorId,
          quaterlyPerformance: [quaterlyPerformanceId],
        });
        console.log({ response });
        return response;
      } else {
        const response = await instance.put("/portfolio-service/originator", {
          ...values,
        });
        console.log({ response });
        return response;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSPOCDetails = createAsyncThunk(
  "updateSPOCDetails",
  async (Dataspocs, rejectWithValue) => {
    console.log({ Dataspocs });
    try {
      const originatorResponse = await instance.put(
        "/portfolio-service/originator/updateSpoc",
        {
          spocs: Dataspocs,
        }
      );

      console.log({ originatorResponse });
      return originatorResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserProfileByUserId = createAsyncThunk(
  "getUserProfileByUserId",
  async ({ userId, id }, rejectWithValue) => {
    try {
      if (id) {
        const usersData = await instance.get(
          `/portfolio-service/originator/getLinkProfileByUserId?userId=${userId}&id=${id}`
          // config
        );
        return usersData;
      }
      const usersData = await instance.get(
        `/portfolio-service/originator/getLinkProfileByUserId?userId=${userId}`
        // config
      );
      return usersData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getDownloadImage = createAsyncThunk(
  "getDownloadImage",
  async ({ id }, { rejectWithValue }) => {
    try {
      const usersData = await instance.get(
        `utility-service/image?id=${id}`
        // config
      );
      return usersData;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const originatorSlice = createSlice({
  name: "originatorSlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
    },
    clearOriginatorState: (state) => {
      state.checkedPages = [
        { pageName: "basicInformation", isChecked: false },
        { pageName: "otherDetails", isChecked: false },
        { pageName: "assignSPOC", isChecked: false },
        { pageName: "kycDocuments", isChecked: false },
        { pageName: "documents", isChecked: false },
      ];
      state.originatorName = "";
      state.panNumber = "";
      state.gstNumbers = [];
      state.originatorType = "";
      state.spocs = [];
      state.panCard = null;
      state.gstCertificate = null;
      state.incorporationCertificate = null;
      state.cancelledCheque = null;
      state.rbiRegisterationCertificate = null;
      state.leiCard = null;
      state.leiNumber = null;
      state.portfolioPerformanceMetrics = [];
      state.financialPerformanceMetrics = [];
      state.loading = false;
      state.originatorTypeList = [];
      state.success = true;
    },
    skipPageAction: (state) => {
      state.panCard = null;
      state.gstCertificate = null;
      state.incorporationCertificate = null;
      state.cancelledCheque = null;
      state.rbiRegisterationCertificate = null;
      state.leiCard = null;
      state.leiNumber = null;
    },
    pageStatusAction: (state, { payload }) => {
      // console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    setSortOrgValue: (state, { payload }) => {
      state.sortOrgValue = payload;
    },
    setSortOrgName: (state, { payload }) => {
      state.sortOrgName = payload;
    },
    basicInformationAction: (state, { payload }) => {
      state.originatorName = payload.originatorName;
      state.originatorType = payload.originatorType;
    },
    othersDetailsTabAction: (state, { payload }) => {
      console.log(payload);
      state.gstNumbers = payload.gstNumbers;
      state.panNumber = payload.panNumber;
    },
    setDuplicateOriginator: (state, { payload }) => {
      state.duplicateOriginator = payload;
    },

    assignSPOCAction: (state, { payload }) => {
      state.spocs = payload;
    },
    originatorIdsAction: (state, { payload }) => {
      console.log({ payload });
      state.originatorIds = payload;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
    setGSTCards: (state, { payload }) => {
      state.allgstCertificate = payload;
    },
    deleteDocument: (state, { payload }) => {
      state[payload.docName] =
        state[payload.docName] &&
        state[payload.docName].filter((val, index) => index != payload.index);
    },
    kycDocumentsAction: (state, { payload }) => {
      state.panCard = payload.panCard;
      state.gstCertificate = payload.gstCertificate;
      state.incorporationCertificate = payload.incorporationCertificate;
      state.cancelledCheque = payload.cancelledCheque;
      state.rbiRegisterationCertificate = payload.rbiRegisterationCertificate;
      state.leiCard = payload.leiCard;
      state.leiNumber = payload.leiNumber;
    },
    documentsAction: (state, { payload }) => {
      state.documents = payload.documents;
    },
    setLeiNumberAction: (state, { payload }) => {
      state.leiNumber = payload;
    },
    kycUpoloadedAction: (state, { payload }) => {
      state.isKYCUploaded = !state.isKYCUploaded;
    },
    deleteAmlFile: (state, { payload }) => {
      state[payload.fileName] = null;
    },
    setDealRedirection: (state, { payload }) => {
      state.dealRedirection = payload;
    },
  },
  extraReducers: (builder) => {
    //? Fetch Originator Type
    builder.addCase(fetchOriginatorType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOriginatorType.fulfilled, (state, { payload }) => {
      // console.log(payload.data.data.data);
      state.loading = false;
      state.originatorTypeList = payload.data.data.data;
    });
    builder.addCase(fetchOriginatorType.rejected, (state, { payload }) => {
      state.loading = false;
      state.originatorTypeList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //? Submit Originator Details
    builder.addCase(submitOriginatorDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitOriginatorDetails.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.checkedPages = [
        { pageName: "basicInformation", isChecked: false },
        { pageName: "otherDetails", isChecked: false },
        { pageName: "assignSPOC", isChecked: false },
        { pageName: "kycDocuments", isChecked: false },
        { pageName: "documents", isChecked: false },
      ];
      state.originatorName = "";
      state.originatorType = "";
      state.spocs = [];
      state.panCard = null;
      state.panNumber = "";
      state.gstNumbers = [];
      state.gstCertificate = null;
      state.allgstCertificate = null;
      state.incorporationCertificate = null;
      state.cancelledCheque = null;
      state.rbiRegisterationCertificate = null;
      state.leiCard = null;
      state.leiNumber = null;
      state.portfolioPerformanceMetrics = [];
      state.financialPerformanceMetrics = [];
      state.loading = false;
      state.originatorTypeList = [];
      state.success = true;
    });
    builder.addCase(submitOriginatorDetails.rejected, (state, { payload }) => {
      state.loading = false;

      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    builder.addCase(checkDeDup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkDeDup.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(checkDeDup.rejected, (state, { payload }) => {
      state.loading = false;
    });

    //? Upload KYC Documents
    builder.addCase(uploadKYCDoc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadKYCDoc.fulfilled, (state, { payload }) => {
      // console.log({ payload });
      state.loading = false;
      state[payload.fileName] = payload.data;
    });
    builder.addCase(uploadKYCDoc.rejected, (state, { payload }) => {
      // console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });

    //? Upload Documents
    builder.addCase(uploadDocuments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadDocuments.fulfilled, (state, { payload }) => {
      // console.log({ payload });
      state.loading = false;
      state[payload.fileName] = [...state[payload.fileName], payload.data];
    });
    builder.addCase(uploadDocuments.rejected, (state, { payload }) => {
      // console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });

    //? Fetch Originator List
    builder.addCase(getOriginatorList.pending, (state) => {
      state.loading = true;
      state.originatorsList = null;
    });
    builder.addCase(getOriginatorList.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.originatorsList = payload?.data;
      state.totalPages = payload?.total;
      state.pageLimit = payload?.limit;
      state.pageNumber = payload?.page;

      if (payload?.data?.length > 0) {
        state.foundOriginatorList = true;
      }
    });
    builder.addCase(getOriginatorList.rejected, (state, { payload }) => {
      state.loading = false;
      state.originatorsList = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //? Fetch Single Originator Data
    builder.addCase(getOriginatorDetails.pending, (state) => {
      state.fetchOriginatorLoding = true;
    });
    builder.addCase(getOriginatorDetails.fulfilled, (state, { payload }) => {
      console.log("payload", payload.data);
      state.fetchOriginatorLoding = false;
      // state.singleOriginatorData = payload.data;
      state.originatorName = payload?.name;
      state.panNumber = payload?.panNumber;
      state.originatorType = payload?.originatorTypeIdData?.name;
      state.spocs = payload?.contactsIdData;
      state.portfolioPerformanceMetrics =
        payload?.portfolioPerformanceMetricsData;
      state.financialPerformanceMetrics =
        payload?.financialPerformanceFocumentData;
      state.quaterlyPerformance = payload?.quaterlyPerformanceData;
      state.panCard = payload?.panCardData;
      state.gstCertificate = payload?.gstCertificateData;
      state.incorporationCertificate = payload?.incorporationCertificationData;
      state.cancelledCheque = payload?.cancelledChequeData;
      state.rbiRegisterationCertificate =
        payload?.rbiCerticationRegistrationData;
      state.leiCard = payload?.leiCardData;
      state.leiNumber = payload?.kycData?.leiNumber;
      state.status = payload?.status;
      state.originatorIdsData = payload?.originatorsData;
    });
    builder.addCase(getOriginatorDetails.rejected, (state, { payload }) => {
      state.fetchOriginatorLoding = false;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //? Update SPOC Details
    builder.addCase(updateSPOCDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSPOCDetails.fulfilled, (state, { payload }) => {
      console.log(payload);
    });
    builder.addCase(updateSPOCDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    //? Upload Quarterly Performance
    builder.addCase(getDownloadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDownloadImage.fulfilled, (state, { payload }) => {
      state.loading = false;
      console.log(payload);
    });
    builder.addCase(getDownloadImage.rejected, (state, { payload }) => {
      state.loading = false;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
    //? Upload getDownloadImage Document
    builder.addCase(uploadQuatarlyDoc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadQuatarlyDoc.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
    });
    builder.addCase(uploadQuatarlyDoc.rejected, (state, { payload }) => {
      state.loading = false;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
    builder.addCase(setGstDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setGstDetails.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.message = "";
    });
    builder.addCase(setGstDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getUserProfileByUserId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserProfileByUserId.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;

      state.originatorProfileId =
        payload?.data?.data[payload?.data?.data.length - 1]._id;
      state.originatorProfileName =
        payload?.data?.data[payload?.data?.data.length - 1].name;
      state.cancelledCheque =
        payload?.data?.data[payload?.data?.data.length - 1].cancelledChequeData;
      state.gstCertificate =
        payload?.data?.data[payload?.data?.data.length - 1].gstCertificateData
          ?.length > 0
          ? payload?.data?.data[payload?.data?.data.length - 1]
              .gstCertificateData
          : null;
      state.incorporationCertificate =
        payload?.data?.data[
          payload?.data?.data.length - 1
        ].incorporationCertificationData;
      state.panCard =
        payload?.data?.data[payload?.data?.data.length - 1].panCardData;
      state.rbiRegisterationCertificate =
        payload?.data?.data[
          payload?.data?.data.length - 1
        ].rbiCerticationRegistrationData;
      state.leiCard =
        payload?.data?.data[payload?.data?.data.length - 1].leiCardData;
      state.leiNumber =
        payload?.data?.data[payload?.data?.data.length - 1].kycData?.leiNumber;
      // state.leiNumber = "acb994939000430040030303";
      // state.leiNumber = "acb994939000430040030303";
      state.isKYCUploaded =
        payload?.data?.data[payload?.data?.data.length - 1].isKycCompleted;
      state.name = payload?.data?.data[payload?.data?.data.length - 1].name;
    });
    builder.addCase(getUserProfileByUserId.rejected, (state, { payload }) => {
      state.loading = false;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
  },
});

export const {
  basicInformationAction,
  othersDetailsTabAction,
  setDuplicateOriginator,
  assignSPOCAction,
  kycDocumentsAction,
  documentsAction,
  pageStatusAction,
  deleteDoc,
  setLeiNumberAction,
  deleteDocument,
  clearSucessAndErrorAction,
  setSortOrgValue,
  setSortOrgName,
  skipPageAction,
  kycUpoloadedAction,
  clearOriginatorState,
  deleteAmlFile,
  originatorIdsAction,
  setDealRedirection,
  setGSTCards,
} = originatorSlice.actions;

export const originatorReducer = originatorSlice.reducer;

import DescriptionIcon from "@mui/icons-material/Description";
import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectComponent } from "./../../components/FormComponents/SelectDocument";
import { AddMoreFileComponent } from "./../../components/Wrapper/OriginatorSelect";
import UploadMultipleDocuments from "./../../components/Wrapper/UploadDocs";

export default function Documents({
  ids,
  setIds,
  options,
  selectedValue,
  selectedOptions,
  section,
  setSelectedOptions,
  index = 0,
  gridWidth = 6,
  selectWidth = "35%",
  docKey = "",
}) {
  const [documentKey, setDocumentKey] = useState(docKey);

  const [state, setState] = useState();

  const handleInputChange = (event) => {
    let oldKey = selectedValue;
    setSelectedOptions((prevState) => {
      let temp = { ...prevState };
      temp[section][index] = event.target.value;

      return temp;
    });

    if (event.target.value !== "Others") {
      setIds((prevState) => {
        let temp = { ...prevState };
        let ifExistInOptions = options.includes(
          oldKey !== "Others" ? oldKey : documentKey
        );
        oldKey = ifExistInOptions ? oldKey : documentKey;
        console.log({ oldKey });
        let prevValue = temp[section][oldKey];
        if (prevValue && prevValue?.length > 0) {
          temp[section][event.target.value] = prevValue;
          delete temp[section][oldKey];
        } else {
          temp[section][event.target.value] = [null];
        }

        return temp;
      });
    } else {
      let randomKey = `Others${index}`;
      setDocumentKey((pre) => randomKey);

      setIds((prevState) => {
        let temp = { ...prevState };
        let prevValue = temp[section][oldKey];
        if (prevValue && prevValue?.length > 0) {
          temp[section][randomKey] = prevValue;
          delete temp[section][oldKey];
        } else {
          temp[section][randomKey] = [null];
        }

        return temp;
      });
    }
  };

  const handleOtherChange = (event) => {
    setIds((prevState) => {
      let temp = { ...prevState };
      let prevValue = temp[section][documentKey];

      if (prevValue && prevValue?.length > 0) {
        temp[section][event.target.value] = prevValue;
        delete temp[section][documentKey];
        if (documentKey == "") {
          temp[section][event.target.value] = [null];
        }
      } else {
        temp[section][event.target.value] = [null];
      }

      return temp;
    });

    setDocumentKey((pre) => event.target.value);
  };

  useEffect(() => {
    console.log({
      ids,
    });
  }, [ids]);

  useEffect(() => {
    if (
      selectedValue === "Others" &&
      !options.includes(Object?.keys(ids[section])[index])
    ) {
      setDocumentKey((pre) => Object?.keys(ids[section])[index]);
    }
  }, [selectedValue]);

  return (
    <>
      <Grid item xs={gridWidth}>
        <>
          <div
            style={{
              paddingTop: "14px",
            }}
          >
            <div
              style={{
                backgroundColor: "#E4EAFA",
                height: "54px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                paddingLeft: "14px",
              }}
            >
              <DescriptionIcon
                sx={{ color: "#5E7387", height: "20px" }}
              ></DescriptionIcon>
              <Typography
                fontWeight="500"
                fontSize="14px"
                width={"90%"}
                sx={{ color: "#5E7387" }}
              >
                <SelectComponent
                  placeholder="Select File Name"
                  key={section + selectedValue + index}
                  value={selectedValue || ""}
                  index={index}
                  compWidth={selectWidth}
                  onClickHandler={handleInputChange}
                  optionList={options}
                  selectedOptions={selectedOptions}
                  section={section}
                  fieldWidth={selectedValue == "Others" ? "200px" : "100%"}
                ></SelectComponent>
                {selectedValue == "Others" && (
                  <TextField
                    size="small"
                    autoComplete="off"
                    variant="standard"
                    value={documentKey}
                    sx={{
                      width: "230px",
                      padding: "0px 10px",
                    }}
                    onChange={handleOtherChange}
                    placeholder="Input file name"
                  />
                )}
              </Typography>
            </div>
            {selectedValue == "Others" && documentKey !== "" ? (
              ids?.[section]?.[documentKey]?.map((item, idx) => {
                return (
                  <div>
                    <UploadMultipleDocuments
                      ids={ids[section]}
                      setIds={setIds}
                      docName={documentKey}
                      index={idx}
                      key={documentKey + section + idx}
                      data={item}
                      section={section}
                    ></UploadMultipleDocuments>
                    <div style={{ paddingTop: "10px" }}></div>
                  </div>
                );
              })
            ) : selectedValue && selectedValue !== "Others" ? (
              <div>
                {ids?.[section]?.[selectedValue]?.map((item, idx) => {
                  return (
                    <UploadMultipleDocuments
                      docName={selectedValue}
                      setdata={setState}
                      ids={ids[section]}
                      data={item}
                      index={idx}
                      setIds={setIds}
                      key={selectedValue + section + idx}
                      section={section}
                    ></UploadMultipleDocuments>
                  );
                })}
                <div style={{ paddingTop: "10px" }}></div>
              </div>
            ) : (
              <div>
                <UploadMultipleDocuments />
                <div style={{ paddingTop: "10px" }}></div>
              </div>
            )}
          </div>
          {selectedValue !== "" && (
            <div style={{ padding: "10px" }}>
              <AddMoreFileComponent
                clickhandler={() => {
                  setIds((prevState) => {
                    let temp = { ...prevState };
                    temp[section][
                      selectedValue == "Others" ? documentKey : selectedValue
                    ].push(null);
                    return temp;
                  });
                }}
              ></AddMoreFileComponent>
            </div>
          )}
        </>
      </Grid>
    </>
  );
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "creditFilters", isChecked: false },
    { pageName: "downloadPool", isChecked: false },
    { pageName: "secondaryAutoCheck", isChecked: false },
    { pageName: "secondaryManualCheck", isChecked: false },
    { pageName: "creditBureauScrubReport", isChecked: false },
  ],
  loanValidation: null,
  allData: [],
  uploadedData: [],
  loading: null,
  error: null,
};

// export const submitKYCLink = createAsyncThunk(
//   "submitKYCLink",
//   async ({ dealId, link }, rejectWithValue) => {
//     try {
//       const response = await instance.post(
//         `/portfolio-service/deal/updateDeal?link=${link}&dealId=${dealId}`
//       );

//       // console.log({ originatorResponse }
//       return response;
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

export const submitKYCLink = createAsyncThunk(
  "submitKYCLink",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      dealId: values.dealId,
      link: values.link,
    });
    try {
      const dealData = await instance.post(
        "/portfolio-service/deal/updateDeal",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const uploadValidationFile = createAsyncThunk(
  "uploadValidationFile",
  async ({ formData, fileName }, rejectWithValue) => {
    console.log({ formData, fileName });
    try {
      const uploadData = await instance.post(
        "/utility-service/upload-file",
        {
          file: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      //
      console.log(uploadData);
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getAllSecondaryAutoCheck = createAsyncThunk(
  "getAllSecondaryAutoCheck",
  async ({ dealId, uniqueCode }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/pool-upload-service/secondaryCheck?dealId=${dealId}&uniqueCode=${uniqueCode}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const goForSecondaryAutoCheck = createAsyncThunk(
  "goForSecondaryAutoCheck",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      dealId: values.dealId,
      uniqueCode: values.uniqueCode,
      ...values,
    });
    try {
      const dealData = await instance.post(
        "/pool-upload-service/secondaryCheck",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const goForRequestReupload = createAsyncThunk(
  "goForRequestReupload",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      dealId: values.dealId,
    });
    try {
      const dealData = await instance.post(
        "/pool-upload-service/request-reupload",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSinglePoolUpload = createAsyncThunk(
  "getSinglePoolUpload",
  async ({ uniqueCode }, rejectWithValue) => {
    try {
      const response = await instance.get(
        `/pool-upload-service/getSinglePoolUpload?uniqueCode=${uniqueCode}`
      );
      console.log({ response });
      return response.data.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const goForReUploadNotification = createAsyncThunk(
  "goForReUploadNotification",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      notification: values.notification,
      notificationType: values.notificationType,
      visibleBy: values.visibleBy,
    });
    try {
      const dealData = await instance.post(
        "/utility-service/notification",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSecondaryManualCheck = createAsyncThunk(
  "getSecondaryManualCheck",
  async ({ dealId, uniqueCode }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/pool-upload-service/secondaryManualCheck?dealId=${dealId}&uniqueCode=${uniqueCode}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const submitSecondaryManualCheck = createAsyncThunk(
  "submitSecondaryManualCheck",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("file", values.file);
    formData.set("dealId", values.dealId);
    formData.set("uniqueCode", values.uniqueCode);
    try {
      const uploadData = await instance.post(
        "/pool-upload-service/secondaryManualCheck",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const secondaryCheckSlice = createSlice({
  name: "secondaryCheckSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    updDocuments: (state, { payload }) => {
      state.loanValidation = payload.loanValidation;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
    deleteDocument: (state, { payload }) => {
      state[payload.docName] =
        state[payload.docName] &&
        state[payload.docName].filter((val, index) => index != payload.index);
    },
    extraReducer: (builder) => {
      builder.addCase(uploadValidationFile.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(uploadValidationFile.fulfilled, (state, { payload }) => {
        console.log({ payload });
        state.loading = false;
        state[payload.fileName] = payload.data;
      });
      builder.addCase(uploadValidationFile.rejected, (state, { payload }) => {
        console.log({ payload });
        state.loading = false;
        state.error = "Something went wrong";
      });

      builder.addCase(getAllSecondaryAutoCheck.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getAllSecondaryAutoCheck.fulfilled, (state, payload) => {
        console.log({ payload });
        //  ;
        // state.loading = false;
        // state.allData = payload;
      });
      builder.addCase(getAllSecondaryAutoCheck.rejected, (state, payload) => {
        console.log({ payload });
        // state.loading = false;
        state.error = "Something went wrong";
      });
      builder.addCase(getSinglePoolUpload.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getSinglePoolUpload.fulfilled, (state, { payload }) => {
        console.log({ payload });
        state.loading = false;
        state.uploadedData = payload;
      });
      builder.addCase(getSinglePoolUpload.rejected, (state, { payload }) => {
        console.log({ payload });
        state.loading = false;
        state.error = "Something went wrong";
      });
      builder.addCase(getSecondaryManualCheck.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(
        getSecondaryManualCheck.fulfilled,
        (state, { payload }) => {
          console.log({ payload });
          state.loading = false;
          // state.uploadedData = payload;
        }
      );
      builder.addCase(
        getSecondaryManualCheck.rejected,
        (state, { payload }) => {
          console.log({ payload });
          state.loading = false;
          state.error = "Something went wrong";
        }
      );
      builder.addCase(goForRequestReupload.rejected, (state, { payload }) => {
        console.log({ payload });
        console.log(payload.response.data.message);
        state.loading = false;
        state.error = payload.response.data.message;
      });
      builder.addCase(
        goForSecondaryAutoCheck.rejected,
        (state, { payload }) => {
          console.log({ payload });
          console.log(payload.response.data.message);
          state.loading = false;
          state.error = payload.response.data.message;
        }
      );
      builder.addCase(
        goForReUploadNotification.rejected,
        (state, { payload }) => {
          console.log({ payload });
          console.log(payload.response.data.message);
          state.loading = false;
          state.error = payload.response.data.message;
        }
      );
      builder.addCase(
        submitSecondaryManualCheck.rejected,
        (state, { payload }) => {
          console.log({ payload });
          console.log(payload.response.data.message);
          state.loading = false;
          state.error = payload.response.data.message;
        }
      );
    },
  },
});

//
export const { pageStatusAction } = secondaryCheckSlice.actions;

export const SecondaryCheckReducer = secondaryCheckSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

export const uploadLoanPool = createAsyncThunk(
  "uploadLoanPool",
  async (
    { loanId, dealId, formData, reUpload = false, reUploadId },
    thunkApi
  ) => {
    try {
      if (!reUpload) {
        const uploadData = await instance.post(
          `utility-service/upload-file`,
          {
            file: formData,
            loanTypeId: loanId,
            dealId: dealId,
            reUpload: reUpload,
            serviceName: "pool-upload-service",
            endpoint: "loanUploadPhase2",
            reUploadId: "",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return {
          ...uploadData,
        };
      }
      const uploadData = await instance.post(
        `utility-service/upload-file`,
        {
          file: formData,
          loanTypeId: loanId,
          dealId: dealId,
          reUpload: reUpload,
          serviceName: "pool-upload-service",
          endpoint: "loanUploadPhase2",
          reUploadId: reUploadId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const updatePoolStatus = createAsyncThunk(
  "updatePoolStatus",
  async ({ poolStatus, dealId, uniqueCode, status }, thunkApi) => {
    console.log(uniqueCode, dealId, poolStatus);
    try {
      if (status) {
        const uploadData = await instance.post(
          `pool-upload-service/approvePool`,
          {
            dealId: dealId,
            uniqueCode: uniqueCode,
            status: poolStatus,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return {
          ...uploadData,
        };
      }
      const uploadData = await instance.post(
        `pool-upload-service/approvePool`,
        {
          dealId: dealId,
          uniqueCode: uniqueCode,
          poolStatus: poolStatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSingleUploadPoolLoanDetails = createAsyncThunk(
  "getSingleUploadPoolLoanDetails",
  async ({ uniqueCode }, thunkApi) => {
    try {
      const data = await instance.get(
        `pool-upload-service/getSinglePoolUpload?uniqueCode=${uniqueCode}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSingleDeal = createAsyncThunk(
  "getSingleDeal",
  async (values, { rejectWithValue }) => {
    try {
      const data = await instance.get(
        `/portfolio-service/originator/getSingleDealById?id=${values}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  checkedPages: [
    { pageName: "dealDetails", isChecked: false },
    { pageName: "loanUploadTemplate", isChecked: false },
    { pageName: "loanUploadSummary", isChecked: false },
  ],
  uploadTemplateData: null,
  loanUpload: null,
  fileName: null,
  uniqueCode: null,
  loanTypeId: null,

  loading: false,
  error: null,
  primaryRejectedFileData: null,
  secondaryRejectedFileData: null,
  primaryTotalRecords: null,
  primarySuccessRecords: null,
  primaryRejectedRecords: null,
  primaryCurrentStatus: null,
  primarySuccessFileData: null,
  // primaryRejectedFileData: null,
};

const poolUploadSlice = createSlice({
  name: "poolUploadSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;
        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    uploadLoanTemplate: (state, { payload }) => {
      console.log({ payload });
      const { fileName, file } = payload;
      state.loanUpload = file;
      state.fileName = fileName;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
    removeUploadLoanTemplate: (state, { payload }) => {
      console.log({ payload });
      const { fileName, file } = payload;
      state.loanUpload = null;
      state.fileName = null;
      state.checkedPages = [
        { pageName: "dealDetails", isChecked: false },
        { pageName: "loanUploadTemplate", isChecked: false },
        { pageName: "loanUploadSummary", isChecked: false },
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadLoanPool.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadLoanPool.fulfilled, (state, { payload }) => {
      // console.log({ payload });
      state.loading = false;
      state.uploadTemplateData = payload.data;
      state.loanUpload = null;
      state.fileName = null;
      state.checkedPages = [
        { pageName: "dealDetails", isChecked: false },
        { pageName: "loanUploadTemplate", isChecked: false },
        { pageName: "loanUploadSummary", isChecked: false },
      ];
    });
    builder.addCase(uploadLoanPool.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });
    builder.addCase(getSingleUploadPoolLoanDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSingleUploadPoolLoanDetails.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.primaryTotalRecords = payload.data.data.data.primaryTotalRecords;
        state.primarySuccessRecords =
          payload.data.data.data.primarySuccessRecords;
        state.primaryRejectedRecords =
          payload.data.data.data.primaryRejectedRecords;
        state.primaryCurrentStatus =
          payload.data.data.data.primaryCurrentStatus;
        state.primarySuccessFileData =
          payload.data.data.data.primarySuccessFileData;
        state.primaryRejectedFileData =
          payload.data.data.data.primaryRejectedFileData;
      }
    );
    builder.addCase(
      getSingleUploadPoolLoanDetails.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = "Something went wrong";
      }
    );
    builder.addCase(getSingleDeal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleDeal.fulfilled, (state, { payload }) => {
      console.log({ payload: payload.data.data.data });
      state.loading = false;
      state.uniqueCode = payload.data.data.data.uniqueCode;
      state.loanTypeId = payload.data.data.data.loanTypeId;
      state.name = payload.data.data.data.name;
    });
    builder.addCase(getSingleDeal.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = "Something went wrong";
    });
    builder.addCase(updatePoolStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePoolStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePoolStatus.rejected, (state) => {
      state.loading = false;
      state.error = "Something went wrong";
    });
  },
});

export const {
  pageStatusAction,
  uploadLoanTemplate,
  removeUploadLoanTemplate,
  deleteDoc,
} = poolUploadSlice.actions;

export const poolUploadReducer = poolUploadSlice.reducer;

import * as React from 'react';
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import Pagination from "../../Pagination/Pagination";
import styles from "./Status.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    getReportList,
    setSortValue,
    setSortName,
} from "../../../redux/Phase2/UploadReportSlice";
import { ArrowDownward } from "@mui/icons-material";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { arrowDownwardStyle, arrowUpwardStyle, statusTopBox2 } from "../TableCSS";
import customStyles from "../Table.module.css"

const MonthEndDealTable = ({
    data,
    page,
    totalRecords,
    header,
    nextPageHandler,
    prevPageHandler,
    setDealId,
    dealId,
}) => {

    const [selectAll, setSelectAll] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState({});

    React.useEffect(() => {
        if (selectAll) {
            
            const selected = {};
            let  itemID =[]
            data.forEach(item => {
                selected[item?.dealData?._id] = true;
                itemID.push(item?.dealData?._id)
            });
            setSelectedItems(selected);
            setDealId(itemID)
        } else {
            setSelectedItems({});
            setDealId([]);
        }
    }, [selectAll, data]);
    
    const handleChange = (itemId) => (event) => {
        const { checked } = event.target;

        setSelectedItems({
            ...selectedItems,
            [itemId]: event.target.checked
        });
        if(checked == true){
            setDealId(dealId => [
                ...dealId, itemId
            ])
        }else {
            // Remove itemId from dealId if unchecked
            setDealId(dealId => dealId.filter(id => id !== itemId));
        }
    };
    
    
    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
    };

    useEffect(() =>{
        if(dealId?.length == 0){
            setSelectAll(false)
        }
    },[dealId])

    const dispatch = useDispatch();

    //? Local Storage Data
    let userData = JSON.parse(localStorage.getItem("UserData"));

    let uploadReportState = useSelector((state) => state.uploadReport);
    //Download Report Function End Here //

    // Styles for Table Cell and Table Row starts here
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        textAlign: "center",
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#EBEEF0",
            color: "#365069",
            fontSize: "14px",
            fontWeight: "800",
            textAlign: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            textAlign: "center",
            fontWeight: "600",
        },
        padding: "20px",
    }));
    // Styles for Table Cell and Table Row ends here

    //Color for the Approval status of the deal in the table row //
    const StatusContainer = ({ statusType }) => {
        console.log({ statusType });
        const Textcolor =
            statusType === "APPROVED" || statusType === "true" || statusType === "COMPLETED" || statusType === "CREATED" 
                ? "#277742"
                : statusType === "REJECTED" || statusType === "false" || statusType === "NOT COMPLETED" || statusType === "NOT CREATED"
                    ? "#996E37"
                    : "#8D3431";

        const boxBackgroudColor =
            statusType === "APPROVED" || statusType === "true" || statusType === "COMPLETED" || statusType === "CREATED"
                ? "#E3F6E9"
                : statusType === "REJECTED" || statusType === "false" || statusType === "NOT COMPLETED" || statusType === "NOT CREATED"
                    ? "#FFF4E7"
                    : "#FCE6E5";
        return (
            <Box
                sx={statusTopBox2}
                style={{
                    color: Textcolor,
                    backgroundColor: boxBackgroudColor
                }}
            >
                <div className={styles.p}>
                    <p style={{ fontSize: "12px", fontWeight: "800" }}>{statusType}</p>
                </div>
            </Box>
        );
    };
    //Color for the Approval status of the deal in the table row ends here //


    const headerFilters = {
        "Upload Name": "name",
        "Name of F1": "uploadedByName",
        "Report Upload Date (F1)": "createdAt",
    };

    const MapHeader = ({ data }) => {
        const [sort, setSort] = useState("");
        useEffect(() => {
            if (sort !== "") {
                // setCallFunc({ data, sort });
                dispatch(setSortValue(!uploadReportState.sortValue));
                dispatch(setSortName(data));
                let sortName = headerFilters[`${data}`];
                if (sortName !== undefined) {
                    dispatch(
                        getReportList({
                            currentPage: page,
                            limit: 10,
                            sortName: sortName,
                            // filterName,
                            sortOrder: uploadReportState.sortValue ? 1 : -1,
                        })
                    );
                }
            }
            return;
        }, [sort]);

        console.log(headerFilters[data]);
        console.log(uploadReportState?.sortFieldName);

        console.log(data === uploadReportState?.sortFieldName);
        return (
            <StyledTableCell
                onClick={() =>
                    data == "Upload Name" ||
                        data == "Name of F1" ||
                        data == "Report Upload Date (F1)"
                        ? setSort(!sort)
                        : null
                }
                style={{
                    cursor:
                        data == "Upload Name" ||
                            data == "Name of F1" ||
                            data == "Report Upload Date (F1)"
                            ? "pointer"
                            : null,
                }}
            >
                {data}

                {data !==
                    uploadReportState?.sortFieldName ? null : uploadReportState?.sortValue !==
                        true ? (
                    <ArrowUpward sx={arrowUpwardStyle} />
                ) : (
                    <ArrowDownward sx={arrowDownwardStyle} />
                )}
            </StyledTableCell>
        );
    };



    return (
        <div className="App">
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{backgroundColor:"#EBEEF0"}}>
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    inputProps={{ 'aria-label': 'Select All Items' }}
                                />
                            </TableCell>
                            {header.map((data) => {
                                return <MapHeader data={data} />;
                            })}
                        </TableRow>
                    </TableHead>

                    {data && data?.length == 0 ? (
                        <div className={customStyles.noRecordFoind}>
                            No Records Found!!
                        </div>
                    ) : (
                        // Table Body Start Here
                        <TableBody>
                            {data &&
                                data?.map((item) => {
                                    return (
                                        <TableRow key={item?.dealData?._id}>
                                                <TableCell >
                                                    <Checkbox
                                                        checked={selectedItems[item?.dealData?._id] || false}
                                                        onChange={handleChange(item?.dealData?._id)}
                                                        inputProps={{ 'aria-label': `Select Item ${item?.dealData?._id}` }}
                                                    />
                                                </TableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {item?.dealData?.dealCode}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {item?.dealData?.name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {item?.totalUploads}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {item?.dealData?.entity}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <StatusContainer
                                                    statusType={item?.accountingEntryGenerated == true ? "CREATED" : "NOT CREATED"}
                                                ></StatusContainer>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <StatusContainer
                                                    statusType={item?.monthEnd}
                                                ></StatusContainer>
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        // Table Body End Here
                    )}
                </Table>
            </TableContainer>

            {/* Pagination Start Here */}
            {data && data.length === 0 ? null : (
                <Pagination
                    Page={page}
                    TotalNoNumber={totalRecords}
                    nextPageHandler={page === totalRecords ? nextPageHandler : nextPageHandler}
                    prevPageHandler={prevPageHandler}
                />
            )}
            {/* Pagination End Here */}
        </div>
    );
};

export default MonthEndDealTable;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import BasicModal from "../../../components/Modal/Modal";
import ReUploadReports from "../../../components/Phase2/UploadReports/ReUploadReports";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import Paths from "../../../components/constants/Path";
import {
  RegenerateEntry,
  changeClosureStatus,
  changeStatus,
  createAccountingReport,
  fetchSingleDealReport,
  finalSubmitForApproval,
  uploadReport,
} from "../../../redux/Phase2/UploadReportSlice";
import {
  fetchQueryList,
  postQuery,
} from "../../../redux/Queries/queriesSlice.js";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { Colors } from "../../../theme/theme";
import Loader from "../../../utils/Loader.jsx";

const ReportsDetails = ({ loanUploadFinalId }) => {
  const [partialLoading, setPartialLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [openMsg, setOpenMsg] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isApproved, setApprove] = useState(false);
  const [msgData, setMsgData] = useState("");
  const [regenratekey, setRegenratekey] = useState(false);
  const [statusData, setStatusData] = useState({
    status: "",
    finalStatus: "",
  });

  const [queryDetails, setQueryDetails] = useState({
    documentId: "",
    queryModule: "Deal",
    query: "",
  });

  // Post Query Handler Function starts here
  const onPostClickHandler = (queryDetails) => {
    if (queryDetails.query == "") {
      dispatch(showErrorAlertAction("Please Enter Query"));
      return;
    }
    dispatch(postQuery(queryDetails)).then((data) => {
      let id = loanUploadFinalId;
      dispatch(fetchQueryList({ id }));
      dispatch(showAlertAction(data.payload.data.message));
      history.push("/phase1listofReports");
    });
  };
  // Post Query Handler Function ends here

  //? Local Storage Data
  let userData = JSON.parse(localStorage.getItem("UserData"));




  let history = useHistory();

  //? useDispatch
  let dispatch = useDispatch();

  // handle input change function starts here
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setStatusData({ ...statusData, [name]: value });
  };
  // handle input change function ends here

  // Placeholder component
  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  //? useSelector
  let uploadReportState = useSelector((state) => state.uploadReport);
  let loading = useSelector((state) => state.uploadReport.loading);
  const [fileState, setFileState] = useState(null);

  //Current Date
  const current = new Date();
  current.setMonth(current.getMonth() - 2);

  // Heading Data
  var heading = `Monthly Collection Report for ${uploadReportState?.singleDealReport?.month}
   ${uploadReportState?.singleDealReport?.year}`;
  var uploadHeading = `Uploaded File for ${uploadReportState?.singleDealReport?.month}
   ${uploadReportState?.singleDealReport?.year}`;
  var accountHeading = `Accounting Report for ${uploadReportState?.singleDealReport?.month}
  ${uploadReportState?.singleDealReport?.year}`;

  //? useEffect for fetching single deal report data starts here
  useEffect(() => {
    dispatch(fetchSingleDealReport(loanUploadFinalId)).then((data) => {

      setStatusData({
        ...statusData,
        ["status"]: data?.payload?.data?.status,
      });
      setRegenratekey(data?.payload?.data?.allowAgainEntryCreation);
      if (data.payload.data.status === "APPROVED") {
        setApprove(true);
      }
    });
  }, [loanUploadFinalId]);
  //? useEffect for fetching single deal report data ends here

  //? Change Status Handler Function starts here
  const handleChangeStatus = () => {
    if (!["APPROVED", "REJECTED"].includes(statusData.status)) {
      dispatch(showErrorAlertAction("Please Select status"));
      return;
    }
    if (statusData.status == "APPROVED") {
      dispatch(
        changeStatus({
          uniqueCode: uploadReportState?.singleDealReport?.uniqueCode,
          Status: statusData.status,
          isfinalUpload: false,
        })
      ).then((res) => {
        if (uploadReportState["loading"] == false) {
          if (res?.payload?.status == 200) {
            handleCreateAccountingEntry();
            dispatch(showAlertAction("Status Changed Successfully"));
            if (statusData.status == "APPROVED") {
              setApprove(true);
            } else {
              setApprove(false);
            }
          } else {
            dispatch(showErrorAlertAction("Something Went Wrong"));
          }
        }
      });
    } else {
      dispatch(
        changeStatus({
          uniqueCode: uploadReportState?.singleDealReport?.uniqueCode,
          Status: statusData.status,
          isfinalUpload: false,
        })
      ).then((res) => {
        if (uploadReportState["loading"] == false) {
          if (res?.payload?.status == 200) {
            console.log("Status Changed");
            dispatch(showAlertAction("Status Changed Successfully"));
            if (statusData.status == "APPROVED") {
              setApprove(true);
            } else {
              setApprove(false);
            }
          } else {
            dispatch(showErrorAlertAction("Something Went Wrong"));
          }
        }
      });
    }
  };
  //? Change Status Handler Function ends here

  //? Create Accounting Entry Handler Function starts here
  const handleCreateAccountingEntry = () => {
    dispatch(
      createAccountingReport(uploadReportState?.singleDealReport?._id)
    ).then((res) => {
      console.log({ res });
      if (loading == false) {
        if (res?.error?.message) {
          dispatch(showErrorAlertAction(res.payload));
        } else {
          dispatch(showAlertAction(res.payload.data.message));
          dispatch(fetchSingleDealReport(loanUploadFinalId));
        }
      }
    });
  };
  //? Create Accounting Entry Handler Function ends here

  //? Change Closure Status Handler Function starts here
  const handleClosureStatus = () => {
    dispatch(
      changeClosureStatus({
        uniqueCode: uploadReportState?.singleDealReport?.uniqueCode,
        Status: statusData.status,
        isfinalUpload:
          statusData.finalStatus == ""
            ? false
            : JSON.parse(statusData.finalStatus),
        proceedWithUploadVal: false,
      })
    ).then((res) => {
      console.log({ res });
      if (uploadReportState["loading"] == false) {
        if (res?.payload?.status == 200) {
          setMsgData(res?.payload?.data?.message);
          setOpenMsg(true);
        } else {
          dispatch(showErrorAlertAction("Something Went Wrong"));
        }
      }
    });
  };
  //? Change Closure Status Handler Function ends here

  const handleClosureStatus1 = () => {
    setMsgLoading(true);
    dispatch(
      changeClosureStatus({
        uniqueCode: uploadReportState?.singleDealReport?.uniqueCode,
        Status: statusData.status,
        isfinalUpload:
          statusData.finalStatus == ""
            ? false
            : JSON.parse(statusData.finalStatus),
        proceedWithUploadVal: true,
      })
    ).then((res) => {

      if (uploadReportState["loading"] == false) {
        if (res?.payload?.status == 200) {
          dispatch(showAlertAction(res?.payload?.message));
          setMsgLoading(false);
          setOpenMsg(false);
        } else {
          dispatch(showErrorAlertAction(res?.payload?.message));
          setMsgLoading(false);
          setOpenMsg(false);
        }
      }
    });
  };

  //? Final Submit monthly upload Handler Function starts here
  const finalSubmitApproval = () => {
    dispatch(
      finalSubmitForApproval(uploadReportState?.singleDealReport?.uniqueCode)
    ).then((res) => {
      if (uploadReportState["loading"] == false) {
        if (res?.payload?.status == 200) {
          dispatch(
            showAlertAction(
              "Monthly Collection Report Sent for Approval Successfully!"
            )
          );
          history.push(Paths.LISTOFREPORTS);
        } else {
          dispatch(showErrorAlertAction("Something Went Wrong"));
        }
      }
    });
  };
  //? Final Submit monthly upload Handler Function ends here

  //? Upload monthly upload Handler Function starts here
  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    if (fileState != null) {
      let formData = new FormData();
      formData.append("file", fileState[0]);
      dispatch(
        uploadReport({
          formData: formData.getAll("file")[0],
          fileName: "monthlyCollectionReportFile",
          isReUpload: true,
          reUploadId: uploadReportState?.singleDealReport?.uniqueCode,
          dealId: uploadReportState?.singleDealReport?.dealId,
          month: uploadReportState?.singleDealReport?.month,
          year: "",
        })
      ).then((res) => {
        //navigate to list of reports
        history.push(Paths.LISTOFREPORTS);
      });
    } else {
      setOpen(true);
    }
  };
  //? Upload monthly upload Handler Function ends here

  //? Partial Upload Handler Function starts here
  const partialSubmitApproval = () => {
    setPartialLoading(true);
    dispatch(
      finalSubmitForApproval(uploadReportState?.singleDealReport?.uniqueCode)
    ).then((res) => {
      console.log({ res });
      if (uploadReportState["loading"] == false) {
        if (res?.error?.message) {
          dispatch(showErrorAlertAction(res.payload));
        } else {
          dispatch(
            showAlertAction(
              "Monthly Collection Report Sent for Approval Successfully!"
            )
          );

          history.push(Paths.LISTOFREPORTS);
        }
      }
    });
  };
  //? Partial Upload Handler Function ends here

  const onClickRegenrateEntryButton = (e) => {
    e.preventDefault();
    dispatch(
      RegenerateEntry({
        id: loanUploadFinalId,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        dispatch(showAlertAction(res.payload.message));
      } else {
        dispatch(showErrorAlertAction("Something Went Wrong"));
      }
    });
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Monthly Collection Report component starts here*/}


          {uploadReportState?.singleDealReport?.successFileData === undefined || uploadReportState?.singleDealReport?.successFileData === null ? null : (
            <WrapperComponent title={heading}>
              <>
                <div
                  style={{
                    padding: "14px",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    color="#808080"
                    fontWeight="400"
                    paddingBottom="10px"
                  >
                    Monthly Collection Report
                  </Typography>

                  {uploadReportState?.singleDealReport === null || uploadReportState?.singleDealReport === undefined ? null : (
                    <>
                      {uploadReportState?.singleDealReport?.successFileData === undefined || uploadReportState?.singleDealReport?.successFileData ===
                        null ? null : (
                        <ViewDownloadDeleteComp
                          id={
                            uploadReportState?.singleDealReport?.successFileData?._id ||
                            ""
                          }
                          fileName={
                            uploadReportState?.singleDealReport
                              ?.successFileData?.fileName ||
                            uploadReportState?.singleDealReport
                              ?.successFileData?.filename
                          }
                          reportData={{
                            data: uploadReportState?.singleDealReport
                              ?.successFileData?.fileUrl,
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            </WrapperComponent>
          )}


          {/* Monthly Collection Report component ends here*/}
          {uploadReportState?.singleDealReport?.uploadedFileData == undefined || uploadReportState?.singleDealReport?.uploadedFileData == null ? null : (
            <WrapperComponent title={uploadHeading}>
              <>
                <div
                  style={{
                    padding: "14px",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    color="#808080"
                    fontWeight="400"
                    paddingBottom="10px"
                  >
                    Uploaded File
                  </Typography>

                  {uploadReportState?.singleDealReport === null || uploadReportState?.singleDealReport === undefined ? null : (
                    <>
                      {uploadReportState?.singleDealReport?.successFileRef ===
                        null ? null : (
                        <ViewDownloadDeleteComp
                          id={
                            uploadReportState?.singleDealReport?.uploadedFileData?._id}
                          fileName={
                            uploadReportState?.singleDealReport
                              ?.uploadedFileData?.fileName ||
                            uploadReportState?.singleDealReport
                              ?.uploadedFileData?.filename
                          }
                          reportData={{
                            data: uploadReportState?.singleDealReport
                              ?.uploadedFileData?.fileUrl,
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            </WrapperComponent>
          )}

          {userData.roles[0] === "F2" ? (
            // F2 components
            <>
              {/* Monthly Collection Report Status component starts here*/}
              {uploadReportState?.singleDealReport?.successFileData ===
                null || uploadReportState?.singleDealReport?.successFileData ===
                undefined ? null : (
                <WrapperComponent
                  title="Monthly Collection Report Status"
                  RegenrateEntry={userData?.roles.length != 1 ? false : true}
                  onClickRegenrateEntryButton={() => {
                    if (userData?.roles.length == 1) {
                      onClickRegenrateEntryButton();
                    }
                  }}
                  regenratekey={regenratekey}
                >
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="space-between"
                      padding={2}
                    >
                      <div
                        style={{
                          justifyContent: "space-around",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Stack width="368px" padding={2}>
                          <TextWrapperComponent text="Select Status">
                            <Select
                              IconComponent={ExpandMoreIcon}
                              value={statusData.status}
                              onChange={handleInputChange("status")}
                              displayEmpty
                              size={"small"}
                              variant="standard"
                              fullWidth
                              renderValue={
                                statusData.status !== ""
                                  ? undefined
                                  : () => (
                                    <Placeholder>
                                      Select an Option
                                    </Placeholder>
                                  )
                              }
                            >
                              <MenuItem value={"APPROVED"}>Approved</MenuItem>
                              {isApproved ? (
                                <MenuItem value={"REJECTED"} disabled>
                                  Rejected
                                </MenuItem>
                              ) : (
                                <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                              )}
                            </Select>
                          </TextWrapperComponent>
                        </Stack>

                        <div style={{ padding: "27px 0px" }}>
                          <OutlineButton
                            text={
                              uploadReportState?.changeStatusLoading
                                ? "Loading"
                                : statusData.status == "APPROVED"
                                  ? "Update & Create"
                                  : "Update"
                            }
                            showIcon={false}
                            isDisable={isApproved}
                            onClickHandler={
                              statusData.status === ""
                                ? () => {
                                  dispatch(
                                    showErrorAlertAction(
                                      "Please Select Status"
                                    )
                                  );
                                }
                                : isApproved
                                  ? null
                                  : handleChangeStatus
                            }
                          ></OutlineButton>
                        </div>
                      </div>
                    </Stack>
                  </>
                </WrapperComponent>
              )}
              {/* Monthly Collection Report Status component ends here*/}

              {/*Monthly Upload Closure Status  component starts here*/}
              {statusData.status === "APPROVED" && uploadReportState?.singleDealReport?.isAccountingEntryCompleted == true ? (
                <>
                  <WrapperComponent title="Monthly Upload Closure Status">
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="space-between"
                        padding={2}
                      >
                        <div
                          style={{
                            justifyContent: "space-around",
                            display: "flex",
                          }}
                        >
                          {statusData.status === "APPROVED" &&
                            uploadReportState?.singleDealReport?.isFinalUpload ===
                            "true" ? (
                            <Stack width="368px" padding={2}>
                              <TextWrapperComponent text="Want to Close the Monthly Upload?">
                                <Select
                                  IconComponent={ExpandMoreIcon}
                                  value={true}
                                  onChange={handleInputChange("finalStatus")}
                                  displayEmpty
                                  size={"small"}
                                  variant="standard"
                                  fullWidth
                                  disabled={true}
                                >
                                  <MenuItem value={true}>Yes</MenuItem>
                                </Select>
                              </TextWrapperComponent>
                            </Stack>
                          ) : statusData.status == "APPROVED" &&
                            uploadReportState?.singleDealReport
                              ?.isFinalUpload === "false" ? (
                            <Stack width="368px" padding={2}>
                              <TextWrapperComponent text="Want to Close the Monthly Upload?">
                                <Select
                                  IconComponent={ExpandMoreIcon}
                                  value={statusData.finalStatus}
                                  onChange={handleInputChange("finalStatus")}
                                  displayEmpty
                                  size={"small"}
                                  variant="standard"
                                  fullWidth
                                  renderValue={
                                    statusData.finalStatus !== ""
                                      ? undefined
                                      : () => (
                                        <Placeholder>
                                          Select an Option
                                        </Placeholder>
                                      )
                                  }
                                >
                                  <MenuItem value={"true"}>Yes</MenuItem>
                                </Select>
                              </TextWrapperComponent>
                            </Stack>
                          ) : null}
                        </div>
                        <OutlineButton
                          text={
                            uploadReportState?.changeClosureStatus
                              ? "Loading"
                              : "Update"
                          }
                          showIcon={false}
                          onClickHandler={
                            statusData.status === ""
                              ? () => {
                                dispatch(
                                  showErrorAlertAction("Please Select Status")
                                );
                              }
                              : statusData.status === "APPROVED" &&
                                uploadReportState?.singleDealReport
                                  ?.isFinalUpload === "true"
                                ? () => null
                                : statusData.status === "APPROVED" &&
                                  statusData.finalStatus === ""
                                  ? () => {
                                    dispatch(
                                      showErrorAlertAction(
                                        "Please Select Final Status"
                                      )
                                    );
                                  }
                                  : handleClosureStatus
                          }
                        ></OutlineButton>

                        <BasicModal
                          open={openMsg}
                          setOpen={setOpenMsg}
                          title="Are you sure you want to close the monthly upload?"
                          positiveText={msgLoading ? "Loading...." : "Yes"}
                          negativeText="No"
                          positiveOnClickHandler={handleClosureStatus1}
                          setloading={setMsgLoading}
                        >
                          <Typography>{msgData}</Typography>
                        </BasicModal>
                      </Stack>
                    </>
                  </WrapperComponent>
                </>
              ) : null}
              {/*Monthly Upload Closure Status  component ends here*/}

              {uploadReportState?.singleDealReport &&
                uploadReportState?.singleDealReport?.accountingEntryData ===
                null || uploadReportState?.singleDealReport &&
                uploadReportState?.singleDealReport?.accountingEntryData ===
                undefined ? null : (
                // Accounting Report component starts here
                <WrapperComponent title={accountHeading}>
                  <>
                    <div
                      style={{
                        padding: "14px",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#808080"
                        fontWeight="400"
                        paddingBottom="10px"
                      >
                        Accounting Report (Final)
                      </Typography>

                      {uploadReportState?.singleDealReport === null || uploadReportState?.singleDealReport === undefined ? null : (
                        <>
                          {uploadReportState?.singleDealReport
                            ?.accountingEntryData === null || uploadReportState?.singleDealReport
                              ?.accountingEntryData === undefined ? null : (
                            <ViewDownloadDeleteComp
                              id={
                                uploadReportState?.singleDealReport
                                  ?.accountingEntryData?._id || ""
                              }
                              fileName={
                                uploadReportState?.singleDealReport
                                  ?.accountingEntryData?.fileName ||
                                uploadReportState?.singleDealReport
                                  ?.accountingEntryData?.filename
                              }
                              reportData={{
                                data: uploadReportState?.singleDealReport
                                  ?.accountingEntryData?.fileUrl,
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </>
                </WrapperComponent>
                // Accounting Report component ends here
              )}
            </>
          ) : uploadReportState["singleDealReport"] === null ? null : (
            <>
              {/* Monthly Collection Report Summary Information file*/}
              <WrapperComponent title="Monthly Collection Report Summary">
                <>
                  <div style={{ padding: "10px" }}>
                    <Grid container>
                      {/* Total Entries Uploaded starts here*/}
                      <Grid item xs={4} direction="row" paddingLeft="20px">
                        <TextWrapperComponent text="Total Entries Uploaded">
                          <Typography
                            fontSize="16px"
                            fontWeight="400"
                            color="#20303F"
                          >
                            {uploadReportState?.singleDealReport?.totalRecords}
                          </Typography>
                        </TextWrapperComponent>
                      </Grid>

                      {/* Total Entries Approved */}
                      <Grid item xs={4}>
                        <TextWrapperComponent text="Total Entries Approved">
                          <Typography
                            fontSize="16px"
                            fontWeight="400"
                            color="#20303F"
                          >
                            {
                              uploadReportState?.singleDealReport
                                ?.successRecords
                            }
                          </Typography>
                        </TextWrapperComponent>
                      </Grid>
                      {/* Total Entries Rejected */}
                      <Grid item xs={4}>
                        <TextWrapperComponent text="Total Entries Rejected">
                          <Typography
                            fontSize="16px"
                            fontWeight="400"
                            color="#20303F"
                          >
                            {
                              uploadReportState?.singleDealReport
                                ?.rejectedRecords
                            }
                          </Typography>
                        </TextWrapperComponent>
                      </Grid>
                    </Grid>
                  </div>
                </>
              </WrapperComponent>
              {/* Monthly Collection Report Summary Information file ends here*/}

              {uploadReportState?.singleDealReport?.rejectedRecords ===
                0 ? null : (
                <>
                  {uploadReportState?.singleDealReport?.status ===
                    "SUBMITTED" ? null : (
                    <>
                      {/* Exception Report component starts here */}
                      <WrapperComponent title="Exception Report">
                        <>
                          <div
                            style={{
                              padding: "14px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="#808080"
                              fontWeight="400"
                              paddingBottom="10px"
                            >
                              Exception Report
                            </Typography>

                            {uploadReportState?.singleDealReport
                              ?.rejectedFileData === null || uploadReportState?.singleDealReport
                                ?.rejectedFileData === undefined ? null : (
                              <>

                                <ViewDownloadDeleteComp
                                  id={
                                    uploadReportState?.singleDealReport
                                      ?.rejectedFileData?._id
                                  }
                                  fileName={
                                    uploadReportState?.singleDealReport
                                      ?.rejectedFileData?.fileName ||
                                    uploadReportState?.singleDealReport
                                      ?.rejectedFileData?.filename
                                  }
                                  reportData={{
                                    data: uploadReportState?.singleDealReport
                                      ?.rejectedFileData?.fileUrl,
                                  }}
                                />

                              </>
                            )}
                          </div>
                        </>
                      </WrapperComponent>
                      {/* Exception Report component ends here */}

                      {/* Re-upload Monthly Collection Report component starts here */}
                      {/* {uploadReportState?.singleDealReport?.currentStatus ===
                        "FAILED" ||
                        uploadReportState?.singleDealReport?.status ===
                        "APPROVED" ? null : (
                        <WrapperComponent title="Re-upload Monthly Collection Report">
                          <>
                            <div
                              style={{
                                padding: "14px",
                              }}
                            >
                              <ReUploadReports
                                setdata={setFileState}
                                data={fileState}
                                docName="monthlyCollectionReportFile"
                              />
                            </div>
                          </>
                        </WrapperComponent>
                      )} */}

                      {/* Re-upload Monthly Collection Report component ends here */}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {userData?.roles.length == 1 ? (
            <>
              {userData.roles[0] === "F2" ? (
                <>
                  {/* Post Query Component starts here*/}
                  <WrapperComponent title="Queries">
                    <>
                      <div
                        style={{
                          padding: "14px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="#365069"
                          fontWeight="700"
                          paddingBottom="10px"
                        >
                          Post a Query
                        </Typography>

                        <Typography
                          fontSize="14px"
                          color="#AFB9C3"
                          fontWeight="400"
                          paddingBottom="10px"
                        >
                          Add your query
                        </Typography>
                        <div
                          style={{
                            justifyContent: "space-between",
                            alignItems: "space-between",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              width: "368px",
                            }}
                          >
                            <TextField
                              variant="standard"
                              fullWidth
                              autoComplete="off"
                              onChange={(e) =>
                                setQueryDetails({
                                  ...queryDetails,
                                  query: e.target.value,
                                  documentId: loanUploadFinalId,
                                })
                              }
                            ></TextField>
                          </div>
                          <OutlineButton
                            text="Post"
                            showIcon={false}
                            onClickHandler={() => onPostClickHandler(queryDetails)}
                          ></OutlineButton>
                        </div>
                      </div>
                    </>
                  </WrapperComponent>
                  {/* Post Query Component ends here*/}
                </>
              ) : (
                <>
                  {/* Submit for approval button Component starts here*/}
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "flex-end",
                      alignContent: "flex-end",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      display: "flex",
                      height: "50px",
                      background: " #ffffff",
                      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <Button
                      onClick={
                        uploadReportState?.singleDealReport?.rejectedRecords === 0
                          ? finalSubmitApproval
                          : nextBtnClickHandler
                      }
                      variant="contained"
                      disabled={
                        uploadReportState?.singleDealReport?.currentStatus ==
                          "FAILED" ||
                          uploadReportState?.singleDealReport?.status ==
                          "SUBMITTED" ||
                          uploadReportState?.singleDealReport?.status == "APPROVED"
                          ? true
                          : false
                      }
                      sx={{
                        backgroundColor: Colors.piramal_orange,
                        color: Colors.white,

                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: Colors.piramal_orange,
                        },
                        "&:disabled": {
                          backgroundColor: "#FAC3B3",
                          color: "white",
                        },
                      }}
                      type="submit"
                    >
                      {uploadReportState?.singleDealReport?.currentStatus ==
                        "PARTIALLY_DONE" &&
                        uploadReportState?.singleDealReport?.status == "PENDING" ? (
                        <>Upload & Check</>
                      ) : (
                        <>Submit for Approval</>
                      )}
                    </Button>
                  </div>
                  {/* Submit for approval button Component ends here*/}
                </>
              )}
            </>
          ) : (<></>)}


          {/* Modal Component starts here*/}
          <BasicModal
            open={open}
            setloading={setPartialLoading}
            setOpen={setOpen}
            title="What you would like to do?"
            positiveText={
              partialLoading ? "Loading...." : "Continue with Exception report"
            }
            negativeText="Re-upload monthly collection report"
            positiveOnClickHandler={
              uploadReportState?.singleDealReport?.totalRecords ===
                uploadReportState?.singleDealReport?.rejectedRecords
                ? null
                : partialSubmitApproval
            }
          ></BasicModal>
          {/* Modal Component ends here*/}

          <div style={{ padding: "10px" }}></div>
        </>
      )}
    </>
  );
};

export default ReportsDetails;

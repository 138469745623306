import styles from "./ViewDownloadDeleteComp.module.css";
import Download from "../../assets/icons/download.svg";
import FileIcon from "../../assets/icons/Icon3.svg";
import { useDispatch } from "react-redux";
import { downloadImageReports } from "../../redux/Phase2/SearchReports";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ViewModal from "../Modal/ViewModal";

const ViewDownloadDeleteComp = ({
  showEye = true,
  id,
  fileName,
  reportData,
  fileExtension
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false); // Track modal visibility
  const [selectedId, setSelectedId] = useState(null); // Track the selected ID for the modal

  console.log({ reportData });

  const getExtension = (fileName) => {
    let extension = fileName?.split(".").pop();
    return extension;
  };

  if(fileExtension == undefined){
    // File extension extraction
    fileExtension = getExtension(fileName);
  }

  const downloadExcel = ({ id, fileName }) => {
    if (!isDisabled) {
      dispatch(downloadImageReports({ id, fileName, setLoading }));
    }
  };
  let userData = JSON.parse(localStorage.getItem("UserData"));
  console.log({ fileExtension });

  const fileExt = [
    "xlsx", "xls", "docx", "ppt", "pptx"
  ]

  // Check if the file extension is 'xlsx'
  const isDisabled = fileExt.includes(fileExtension)
    && userData?.roles.length != 1
    && fileName !== "Finalized Pool.xlsx";

  // Handle opening the modal and passing the id
  const handleIconClick = (id) => {
    setSelectedId(id); // Set the selected ID for the modal
    setModalOpen(true); // Open the modal
  }

  return (
    <>
      <div
        className={styles.wrapper}

      >
        <div className={styles.filenameWrapper}>
          <img src={FileIcon} />

          {!loading && fileName && (
            <p>
              {fileName.length > 20 ? fileName.slice(0, 20) + "..." : fileName}
            </p>
          )}
          {loading && (
            <marquee>
              <p>Please Wait your file is downloading...</p>
            </marquee>
          )}
        </div>
          <div className={styles.iconsWrapper} style={{
          filter: isDisabled
            ? "grayscale(100%) brightness(0.5)" // Disabled effect
            : "none", // Normal state
          cursor: isDisabled ? "not-allowed" : "pointer", // Disabled cursor
        }}>
          <div>
            {loading ? (
                <div className={styles.singleIconWrapper}>
                <CircularProgress size={20} />
              </div>
            ) : (
              <div >
              {/* Conditionally render RemoveRedEyeOutlinedIcon if fileExtension is not in fileExt array, otherwise show Download icon */}
              {!fileExt.includes(fileExtension) && userData?.roles.length != 1 ? (
                <div className={styles.singleIconWrapper} onClick={() => handleIconClick(id)}>
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fontSize: '1rem' }}
                  />
                </div>
              ) : (
                <div className={styles.singleIconWrapper} onClick={loading || isDisabled ? () => { } : () => downloadExcel({ id, fileName })}>
                <img src={Download} />
              </div>
            )}
          </div>
            )}
        </div>
      </div>
      </div>
      {/* Render ViewModal and pass the selected id */}
      {isModalOpen && (
        <ViewModal
          id={selectedId}
          open={isModalOpen}
          onClose={() => setModalOpen(false)} // Handle modal close
        />
      )}
    </>
  );
};

export default ViewDownloadDeleteComp;
